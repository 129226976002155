import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, firstValueFrom, from, of, take } from "rxjs";
import { InternetConnectionService } from "./internet-connection.service";
import { HttpClient } from "@angular/common/http";
import { PermissionsService } from "./permissions.service";
import { TranslocoService } from "@ngneat/transloco";
import { api_v1 } from "environments/environment";
import { DbSynchronization } from "../models/DbSynchronization";
import { SyncReponse } from "../models/SyncResponse";
import { UserPermissions } from "../models/UserPermissions";
import { Utils } from "../others/utils";
import { DbService } from "./db.service";
import { AppDB } from "../models/db";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { LoadingDialogComponent } from "../dialogs/loading-dialog/loading-dialog.component";
import { MatDialog, MatDialogState } from "@angular/material/dialog";
import Dexie, { Table } from "dexie";
import { ConfirmDialogComponent } from "../dialogs/confirm-dialog/confirm-dialog.component";
import { ApplicationData } from "../data/application-data";
import { AuthService } from "app/core/auth/auth.service";
import { CONST } from "../data/const";


@Injectable({
  providedIn: 'root'
})
export class SynchronizationService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _loadingDialog = null

  // Tables
  // Order is important
  private tables = [
    'sources',
    'templates',
    'messages',
    'currencies',
    'additionalServices',
    'rooms',
    'clients',
    'reservations',
    'logs',
    'invoices',
    'invoicesItems',
    'invoicesPrepayment',
    'invoicesCorrection',
    'employees',
    'rates',
    'prices',
    'pricesBooking',
    'notifications',
    'pricingModel',
    'meals'
  ]

  private _synchronizationStatus = new BehaviorSubject<any>({ isSynchronizing: false, savingStatus: null, hasSuccessfulSynchronize: false, changeOccured: false, currentPage: 0, allPages: 0 });
  currentSynchronizationStatus = this._synchronizationStatus.asObservable();

  // Counters
  reservationsCount = 0
  private _db: AppDB

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  // Get them on db load, then update variables
  // It has to be done like this, to update multiple open carts in memory data
  private _lastSyncAt = null
  // Must be declared as date
  private _migrationVersion = CONST.DEFAULT_MIGRATION_VERSION

  constructor(
    private http: HttpClient,
    private ics: InternetConnectionService,
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService,
    private permissionsService: PermissionsService,
    private _translate: TranslocoService,
    private _dialog: MatDialog,
    private _authService: AuthService,
  ) {

    // If user is logged out clean last sync at
    this._subscriptions.push(this._authService._isLoggedIn$.subscribe({
      next: (data) => {
        if (data == false) {
          this._lastSyncAt = null
          this._migrationVersion = CONST.DEFAULT_MIGRATION_VERSION
        }
      }
    }))

    // Synchronize when offline mode is turned off
    this._subscriptions.push(this.ics.offlineMode.subscribe({
      next: (mode) => {
        if (mode == false) this.newSynchronize('Offline mode off.')
      }
    }))

    this._subscriptions.push(
      this._dbService.getDatabase()
        .subscribe({
          next: async (data) => {
            
            this._db = data

            // Set last sync as null
            // If last sync is null it will try to get default value or value from database
            this._lastSyncAt = null

            // Get last sync on every database change
            // Reconnect or login
            if (this._db == null) return
            
            await this.getLastSyncFromDatabase()
            
          }
        }))
  }

  async getLastSyncFromDatabase() {

    if (!this._db) return
    const { lastSync, migrationVersion } = await this.getLastSyncDate()

    if(lastSync == null || migrationVersion == null) return false
    this._lastSyncAt = lastSync
    this._migrationVersion = migrationVersion

    if (this._lastSyncAt == null) this._lastSyncAt = CONST.DEFAULT_SYNC_TIME
    if (this._migrationVersion == null) this._migrationVersion = CONST.DEFAULT_MIGRATION_VERSION

    return true
  }

  getLastSyncAt() {
    return this._lastSyncAt;
  }
  async synchronizeWithPendingCheck(from: string) {
    await this.newSynchronize(from)
  }

  getAll(): Observable<any> {
    return from(this.newSynchronize('Resolver'))
  }

  async newSynchronize(from: string) {


    console.warn("[Synchronization Service]: Sync incoming from: " + from);
    if (this._db == null || typeof this._db == 'undefined') { console.warn("[Synchronization Service]: Database is not loaded yet."); return; }


    // If _lastSyncAt is null get last sync from database
    if (this._lastSyncAt == null) {
      const isSuccessfullyLoadedSync = await this.getLastSyncFromDatabase()
      if(!isSuccessfullyLoadedSync) {
        console.log(`[Synchronization Service]: Failed to get last sync from: ${from}. Synchronization stopped.`)
        return
      }
    }

    if (this._lastSyncAt == null) {
      console.warn("[Synchronization Service]: Invalid value of _lastSyncAt. Null given.")
      return
    }

    if (!this.ics.isOnline()) return;
    
    let t1 = new Date().getTime();
    this.reservationsCount = 0;
    if (this._synchronizationStatus.value.isSynchronizing) {
      console.warn("[Synchronization Service]: Sync (from " + from + ") is in progress. Can't sync right now.");
      return false;
    }

    this._synchronizationStatus.next({
      error: null,
      isSynchronizing: true,
      hasSuccessfulSynchronize: false,
      changeOccured: false
    })

    const toPaginate = []

    this.tables.forEach(table => {
      toPaginate.push({ table: table, allPages: 0, currentPage: 0 })
    })


    this._synchronizationStatus.next({ error: null, isSynchronizing: true, savingStatus: null, hasSuccessfulSynchronize: false, data: null, lastSyncAt: this._lastSyncAt, changeOccured: false, currentPage: 0, allPages: 0 })


    const dataToPush: { table: string, data: any }[] = []

    // Initial request
    const syncSub = this.http.post<SyncReponse>(
      api_v1 + 'sync',
      {
        lastSync: this._lastSyncAt,
        pageRooms: 1,
        pageReservations: 1,
        pageEmployees: 1,
        pageLogs: 1,
        pageCurrencies: 1,
        pageMessages: 1,
        pageClients: 1,
        pageAdditionalServices: 1,
        pageInvoices: 1,
        pageInvoicesCorrection: 1,
        pageInvoicesPrepayment: 1,
        pageInvoicesItems: 1,
        pagePrices: 1,
        pageBookingPrices: 1,
        pagePricingModel: 1,
        pageRates: 1,
        pageSources: 1,
        pageTemplates: 1,
        pageNotifications: 1,
        pageMeals: 1,
      }
    )
    .pipe(take(1))
    .subscribe(
      {
        next: async (response: any) => {

          let data = response.data
          let changeOccured = false;
          let biggestPage = 0;
          if (data != null) {

            // If migration version exists
            // Detect from response
            // If needed perform application update stop synchronization
            // Then reload all
            if (this._migrationVersion != CONST.DEFAULT_MIGRATION_VERSION || typeof this._migrationVersion == 'undefined') {
              if (this._migrationVersion != data.migrationVersion || typeof this._migrationVersion == 'undefined') {
                console.warn(['[Synchronization Service]: Application is updating'])
                this._showAppUpdateDialog()
                this._hardReset()
                syncSub.unsubscribe()
                return
              }
            }

            this.setMigrationVersion(data.migrationVersion)
            this._migrationVersion = data.migrationVersion
            // If it's not a first synchronization
            // Detect changes in user permissions
            // Break syncrhonization and re run.


            if (Utils.isDefined(data, 'permissions')) {
              if (this._lastSyncAt != CONST.DEFAULT_SYNC_TIME && typeof data.permissions.employeeId != 'undefined') {

                console.log("[Synchronization Service] Permissions updated.")
                this.permissionsService.permissions.next(new UserPermissions(data.permissions))

                this._synchronizationStatus.value.isSynchronizing = false
                this._synchronizationStatus.next(this._synchronizationStatus.value)

                console.log("[Synchronization Service] Cleaning tables.")
                await this._db.tables.forEach(async (table) => {
                  if (table.name != 'userSettings') await table.clear();
                })

                await this.setLastSyncDate(CONST.DEFAULT_SYNC_TIME)
                this._lastSyncAt = CONST.DEFAULT_SYNC_TIME
                setTimeout(async () => {
                  this._synchronizationStatus.next({ error: null, isSynchronizing: false, savingStatus: null, hasSuccessfulSynchronize: false, lastSyncAt: this._lastSyncAt, changeOccured: false })
                  // await this.synchronizeWithPendingCheck("[Synchronization Service] Permission updater.")
                  window.location.reload()
                }, 50)
                syncSub.unsubscribe()
                return;
              }
            }

            toPaginate.map(x => x.table).forEach(async table => {

              let syncConfig = toPaginate.filter(x => x.table == table)[0];
              if (typeof syncConfig != 'undefined') {
                syncConfig.allPages = data[table].allPages;
                syncConfig.currentPage = data[table].currentPage;

                if (biggestPage < syncConfig.allPages) {
                  biggestPage = syncConfig.allPages;
                }
              }
            })

            this._synchronizationStatus.next({
              error: null,
              isSynchronizing: true,
              hasSuccessfulSynchronize: false,
              changeOccured: false,
              lastSyncAt: this._lastSyncAt,
              currentPage: 1,
              allPages: biggestPage
            })

            changeOccured = true;
            console.log(`[Synchronization Service]: Detected ${biggestPage} pages to import.`)
            for (let i = 1; i <= biggestPage; i++) {

              let hasError = false;
              let err = null;
              if (i > 1) {
                await this.getNewSyncPage(i).then((response: any) => {
                  data = response.data
                })
                  .catch((e) => {
                    hasError = true;
                    err = e;
                  })
              }

              if (hasError) {
                this._synchronizationStatus.next({ error: err, isSynchronizing: false, savingStatus: null, hasSuccessfulSynchronize: false, lastSyncAt: this._lastSyncAt, changeOccured: false, currentPage: i, allPages: biggestPage })
                syncSub.unsubscribe()
                return;
              }

              for (let tI = 0; tI < toPaginate.length; tI++) {
                const table = toPaginate[tI].table;
                if (typeof data[table] != 'undefined' && data[table].totalItems > 0) {

                  // Save data to database
                  let temp = [];

                  data[table].items = Utils.HTMLEntityDecodeArray(data[table].items);
                  data[table].items.forEach(row => {
                    temp.push(row);
                  })

                  //Take first part and check if there is more pages
                  if (temp.length > 0) {
                    this._synchronizationStatus.next({ error: null, isSynchronizing: true, savingStatus: null, hasSuccessfulSynchronize: false, lastSyncAt: this._lastSyncAt, changeOccured: false, currentPage: i, allPages: biggestPage })

                    // Update data in database
                    try {

                      if (table == 'prices') {
                        temp.forEach(price => {
                          if (Utils.isJSON(price.prices)) price.prices = JSON.parse(price.prices);
                        })
                      }

                      else if (table == 'logs') {
                        temp.forEach(history => {
                          if (Utils.isJSON(history.objectId)) history.objectId = JSON.parse(history.objectId);
                        })
                      }

                      dataToPush.push({ table: table, data: temp })

                    }
                    catch (ex) {
                      if (!ex) console.warn(`[Synchronization Service]: Error thrown. No details.`)
                      else console.warn(`${ex.message}`)
                      syncSub.unsubscribe()
                      return;
                    }
                  }
                }
              }

            }

          }

          const saveResult = await this.saveDataToDatabase(dataToPush)
          if (saveResult != true) {

            this._showDatabseErrorDialog()
            setTimeout(() => {
              this._synchronizationStatus.next({ error: 'Couldnt save data.', isSynchronizing: false, savingStatus: null, hasSuccessfulSynchronize: false, changeOccured: false, lastSyncAt: this._lastSyncAt })
            }, 100)
            syncSub.unsubscribe()
            return
          }


          // If there is more than 1 page reload app
          if (biggestPage > 1) {
            await this.setLastSyncDate(data.lastSync)
            setTimeout(() => {
              window.location.reload()
            }, 100)
          }
          // else update inmemory data
          else {
            dataToPush.forEach(row => {
              this._dataSynchronizerService.next(row.table, row.data)
            })
          }




          this.setLastSyncDate(data.lastSync)
          this._lastSyncAt = data.lastSync
          this._synchronizationStatus.next({ error: null, isSynchronizing: true, savingStatus: null, hasSuccessfulSynchronize: true, changeOccured: changeOccured })
          let t2 = new Date().getTime();
          console.warn(`[Synchronization]: Sync time: ${t2 - t1}ms (${((t2 - t1) / 1000).toFixed(4)} sec)`);

          setTimeout(() => {
            this._synchronizationStatus.next({ error: null, isSynchronizing: false, savingStatus: null, hasSuccessfulSynchronize: false, changeOccured: false, lastSyncAt: this._lastSyncAt })
          }, 100)

        },
        error: (err) => {
          this._synchronizationStatus.next({ error: err, isSynchronizing: false, savingStatus: null, hasSuccessfulSynchronize: false, changeOccured: false })
        }
      }
    )
  }

  private async setLastSyncDate(lastSync) {
    if (!this._db) return;
    console.warn(`[Synchronization Service]: Saving last synchronization date time: ${this._db.name}-lastSync ${lastSync}`);
    await this._db.synchronizations.where("id").equals(1).modify({ lastSync: lastSync });
  }

  private async setMigrationVersion(migrationVersion) {
    if (!this._db) return;
    console.warn(`[Synchronization Service]: Saving migration version: ${migrationVersion}`);
    await this._db.synchronizations.where("id").equals(1).modify({ migrationVersion: migrationVersion });
  }


  public async getLastSyncDate() {

    if (!this._db) return;
    let sync: any = null;
  
    try {
      // Start a transaction on the "synchronizations" table
      await this._db.transaction('rw', this._db.synchronizations, async () => {
        // Get last sync date from the user database.
        sync = await this._db.synchronizations.get({ id: 1 });
  
        // If not exists, try to add the record
        if (!sync) {
          sync = new DbSynchronization(1, CONST.DEFAULT_SYNC_TIME, CONST.DEFAULT_MIGRATION_VERSION);
          await this._db.synchronizations.put(sync);
        }
      });
  
      console.warn(`[Synchronization Service] Get last sync: ${this._db.name} ${sync.lastSync}`);
      return {
        lastSync: sync.lastSync,
        migrationVersion: sync.migrationVersion
      };
  
    } catch (error) {
      console.error(`[Synchronization Service] Error getting last sync date: ${error}`);
      // Handle error accordingly, possibly rethrow or return a default value
      return {
        lastSync: null,
        migrationVersion: null
      };
    }
  }



  // Do not add here foreach loop (it prevents error handler)
  async saveDataToDatabase(data) {

    try {
      if (data.length == 0) return true
      const tables = new Set(data.map(x => x.table))
      console.log("Tables: ",tables)
      const dexieTables: Table<any>[] = Array.from(tables).map((x: string) => this._db[x])



      const tableData: TableDataObject = data.reduce((acc, row) => {
        acc[row.table] = acc[row.table] || [];
        acc[row.table] = acc[row.table].concat(row.data);
        return acc;
      }, {});

      await this._db.transaction("rw", dexieTables, async tx => {
        const keys = Object.keys(tableData)

        for (let x = 0; x < keys.length; x++) {
          const table = keys[x]

          await this._db[table].bulkPut(tableData[table]).catch(Dexie.BulkError, function (e) {
            console.log(`[Synchronization Service]: Pushing ${e.failures.length} failed to ${table} table.`)
          }).catch((e) => {
            console.warn('[Synchronization Service]: Invalid data when bulk put to table. ', tableData[table])
            console.error(`[Synchronization Service] Data not fully saved in ${table} table`)
          })
          this._synchronizationStatus.next({ error: null, isSynchronizing: true, savingStatus: { success: x + 1, all: keys.length }, hasSuccessfulSynchronize: true, changeOccured: this._synchronizationStatus.value.changeOccured })

          console.log(`[Synchronization Service]: Pushing ${tableData[table].length} rows to ${table} table.`)
        }
      })
      return true
    }
    catch (error) {
      console.error("Error saving data to database:", error);
      return false; // Reject the Promise on error
    }
  }

  // Getting new page
  async getNewSyncPage(i) {
    return firstValueFrom(
      this.http.post<SyncReponse>(
        api_v1 + 'sync',
        {
          lastSync: this._lastSyncAt,
          pageRooms: i,
          pageReservations: i,
          pageEmployees: i,
          pageLogs: i,
          pageCurrencies: i,
          pageMessages: i,
          pageClients: i,
          pageAdditionalServices: i,
          pageInvoices: i,
          pageInvoicesCorrection: i,
          pageInvoicesPrepayment: i,
          pageInvoicesItems: i,
          pagePrices: i,
          pageBookingPrices: 1,
          pagePricingModel: i,
          pageRates: i,
          pageSources: i,
          pageTemplates: i,
          pageNotifications: i,
          pageMeals: i
        }
      )
    )
  }

  private async _hardReset() {

    try {
      const dbs = await Dexie.getDatabaseNames()

      dbs.forEach(db => {
        const deleteRequest = indexedDB.deleteDatabase(db);

        deleteRequest.onsuccess = () => {
          console.log(`[Synchronization Service]: Updating application databases: ${db}`);
        };

        deleteRequest.onerror = (error) => {
          console.error(`[Synchronization Service]: Error deleting database ${db}:`, error);
        };
      })
    } catch (ex) {
      console.log([`[SynchronizationService]: ${ex.message}`])
    }


    setTimeout(() => {
      window.location.reload()
    }, 3000)

    return;
  }

  private _showAppUpdateDialog() {
    if (Utils.isDefined(this._loadingDialog, "id")) if (this._loadingDialog.getState() === MatDialogState.OPEN) return

    this._loadingDialog = this._dialog.open(LoadingDialogComponent, {
      data: {
        title: this._translate.translate('aktualizacja_aplikacji'),
        description: this._translate.translate('trwa_aktualizacja_aplikacji_prosze_czekac')
      },
      maxWidth: '100%',
      disableClose: true
    })

  }

  private _showDatabseErrorDialog() {

    const dialog = this._dialog.open(ConfirmDialogComponent, {
      data: {
        title: "[10001] " + this._translate.translate('wystapil_problem_podczas_operacji'),
        message: this._translate.translate('jezeli_problem_pojawi_sie_jeszcze_raz_skontaktuj_sie_z_administratorem_serwisu', { emailAddress: ApplicationData.ContactEmailAddress }),
        confirmText: this._translate.translate('sprobuj_ponownie'),
        type: 'warning'
      },
      maxWidth: '100%',
      disableClose: true
    })

    dialog.afterClosed().subscribe({
      next: (data) => {
        if (data?.action == true) {
          this._hardReset()
        }
      }
    })
  }
}

export interface TableDataObject {
  [key: string]: any
}