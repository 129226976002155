import { DbReservation } from "app/core/shared/models/DbReservation";

export const reservationClientFilter = ({reservation, value}: {reservation:  DbReservation, value:string}) => {
    let forename = '';
    let name = '';
    let imieNazwisko = '';
    let nazwiskoImie = '';

    let client = reservation['client']
    if (client != null && typeof client != 'undefined'){
      if (typeof client['forename'] != 'undefined' && client['forename'] != null) { forename = client.forenameToLowerCase }
      if (typeof client['name'] != 'undefined' && client['name'] != null) { name = client.nameToLowerCase }
  
      if (
        forename.length > 0 && name.length > 0
      ) {
        imieNazwisko = `${forename} ${name}`
        nazwiskoImie = `${name} ${forename}`
      }
  
      if (forename.includes(value)) { return true }
      else if (name.includes(value)) { return true }
      else if (imieNazwisko.includes(value)) { return true }
      else if (nazwiskoImie.includes(value)) { return true }
      else if (client.email.includes(value)) return true
      else if (client.phone.includes(value)) return true
  
      else if(reservation.reservationNo != null) {
        if(reservation.reservationNo.toString().includes(value.replace('#','').toString())) return true
      }
  
      return false;
    }

}