
    <!-- Upgrade -->
    <div *ngIf="subscriptionState.isTestMode == true && subscriptionState.expiredInDays > 7" class="relative flex flex-0 items-center justify-start w-full p-2 z-49 border-t dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <fuse-alert
            class="w-full"
            [appearance]="'outline'"
            [dismissible]="true"
            [type]="'primary'">
            <mat-icon fuseAlertIcon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
            Jesteś w okresie testowym. Masz dostęp do pełnej funkcjonalności. Okres testowy kończy się za <b>{{subscriptionState.expiredInDays}} dni</b>.

            <button mat-flat-button color="primary" class="h-6 min-h-6 ml-2 px-2 text-sm" [routerLink]="'/app/subscription/pricing' | localize">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:arrow-long-right'"></mat-icon>
                Kup premium
            </button>
        </fuse-alert>
    </div>

    <div *ngIf="subscriptionState.expiredInDays > 3 && subscriptionState.expiredInDays < 7" class="relative flex flex-0 items-center justify-start w-full p-2 z-49 border-t dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <fuse-alert
            class="w-full"
            [appearance]="'outline'"
            [dismissible]="false"
            [type]="'warning'">
            <mat-icon fuseAlertIcon [svgIcon]="'heroicons_outline:clock'"></mat-icon>
            Pozostało Ci <b>7 dni</b> subskrypcji, przedłuż subskrypcję, aby nie stracić dostępu do aplikacji.

            <button mat-flat-button color="primary" class="h-6 min-h-6 ml-2 px-2 text-sm" [routerLink]="'/app/subscription/pricing' | localize">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:arrow-long-right'"></mat-icon>
                Przedłuż
            </button>
        </fuse-alert>
    </div>

    <div *ngIf="subscriptionState.expiredInDays > 0 && subscriptionState.expiredInDays < 3" class="relative flex flex-0 items-center justify-start w-full p-2 z-49 border-t dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <fuse-alert
            class="w-full"
            [appearance]="'outline'"
            [dismissible]="false"
            [type]="'warn'">
            <mat-icon fuseAlertIcon [svgIcon]="'heroicons_outline:clock'"></mat-icon>
            Pozostało Ci <b>3 dni</b> subskrypcji, przedłuż subskrypcję, aby nie stracić dostępu do aplikacji.

            <button mat-flat-button color="warn" class="h-6 min-h-6 ml-2 px-2 text-sm" [routerLink]="'/app/subscription/pricing' | localize">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:arrow-long-right'"></mat-icon>
                Przedłuż
            </button>
        </fuse-alert>
    </div>

    <div *ngIf="subscriptionState.expiredInDays == 0" class="relative flex flex-0 items-center justify-start w-full p-2 z-49 border-t dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <fuse-alert
            class="w-full"
            [appearance]="'outline'"
            [dismissible]="false"
            [type]="'warn'">
            <mat-icon fuseAlertIcon [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
            Twoja subskrypcja <b>straciła ważność!</b> przedłuż subskrypcję, aby nie stracić dostępu do aplikacji.

            <button mat-flat-button color="warn" class="h-6 min-h-6 ml-2 px-2 text-sm" [routerLink]="'/app/subscription/pricing' | localize">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:arrow-long-right'"></mat-icon>
                Przedłuż
            </button>
        </fuse-alert>
    </div>