import { APP_INITIALIZER } from "@angular/core";
import { ApiService } from "./api.service";
import { SettingsService } from "./settings.service";
import { CalendarReservationService } from "./calendar-reservation.service";
import { AudioRecordingService } from "./audio-recording.service";
import { DataChangeTrackingService } from "./data-change-tracking.service";
import { CollisionService } from "./collision.service";
import { HandlingService } from "./handling.service";
import { InternetConnectionService } from "./internet-connection.service";
import { PanelService } from "./panel.service";
import { DialogService } from "./dialog.service";

const services = [
    ApiService,
    SettingsService,
    CalendarReservationService,
    AudioRecordingService,
    DataChangeTrackingService,
    
    CollisionService,
    HandlingService,
    InternetConnectionService,
    PanelService,
    DialogService
]

    export const provideServices = () => {
        
        let providers  = []
        if (services )
        {
            providers.push(services);
        }

        return providers;
    }