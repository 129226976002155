<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <div class="rounded-xl px-2 py-0.5 shadow bg-white">
                    <img class="w-7" src="/assets/images/logo/logo.svg">
                </div>

            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <notifications></notifications>
                <user-settings *ngIf="permissions.has('settings')"></user-settings>
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4">
            <div class="relative w-24 h-24 cursor-pointer" [routerLink]="'/app/my-account' | localize">
                <user-avatar-image customClass="w-full h-full rounded-full text-5xl"
                    alt="Users avatar"></user-avatar-image>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user?.userData?.forename}} {{user?.userData?.name}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user?.userData?.email}}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img class="max-w-36" src="/assets/images/logo/logo-text-on-dark.svg">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <div *ngIf="isOfflineMode" class="flex justify-center items-center w-full py-2 text-white bg-red-600 text-sm">{{'tryb_offline' | transloco}}</div>
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b dark:border-gray-800 bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <collisions></collisions>
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <synchronization></synchronization>
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <user [showAvatar]="false"></user>
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button> -->
        </div>
    </div>

    <subscription-action-state-alert></subscription-action-state-alert>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <drawer-router-outlet *ngIf="true"></drawer-router-outlet>
    </div>



</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->