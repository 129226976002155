<div class="relative flex flex-col max-w-180 max-h-full sm:max-h-screen">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{title}} ({{selected.length}})</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <div class="pt-6 px-6">
        <mat-form-field class="fuse-mat-dense my-6 fuse-mat-rounded w-full min-w-50" [subscriptSizing]="'dynamic'">
            <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            <input #searchInput (keyup)="search(searchInput.value)" matInput type="text"
                [placeholder]="translate.translate('filtruj')">
            <button *ngIf="searchInput.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="searchInput.value = ''; clear()">
                <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
            </button>
        </mat-form-field>
    
        <button *ngIf="rooms.length - 1 != selected.length - 1" (click)="selectAll()" mat-flat-button>{{"zaznacz_wszystkie" | transloco}}</button>
        <button *ngIf="rooms.length - 1 == selected.length - 1" (click)="deselectAll()" mat-flat-button>{{"odznacz_wszystkie" | transloco}}</button>
    
        <div class="mt-4 border-t"></div>
    </div>
    

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-scroll">
            
            
            

            <!-- <cdk-virtual-scroll-viewport itemSize="40" class="example-viewport w-full"> -->
                <ng-container *ngFor="let room of rooms"> <!--*cdkVirtualFor-->
                    <div
                        class="flex items-center h-10 min-h-10 pl-1 pr-4 cursor-pointer hover:bg-hover"
                        matRipple
                        [ngClass]="{'disabled' : isDisabled(room.roomId)}"
                        (click)="toggle(room.roomId)">
                        <mat-checkbox
                            class="flex items-center h-10 min-h-10 pointer-events-none"
                            [checked]="isSelected(room.roomId)"
                            [color]="'primary'"
                            [disableRipple]="true">
                        </mat-checkbox>
                        <div class="ml-1">
                            <div class="flex items-center">
                                <div class="text-md">
                                    {{room.name}}
                                </div>
                                <mat-icon class="icon-size-5 ml-1.5" [svgIcon]="'heroicons_solid:user'"></mat-icon>
                                x{{room.persons}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            <!-- </cdk-virtual-scroll-viewport> -->


        </div>
    </div>
    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-stroked-button (click)="close(false)" class="me-2">
                    {{'anuluj' | transloco}}
                </button>
                <!-- Confirm -->
                <button mat-dialog-close mat-flat-button color="primary" (click)="close(true)">
                    {{confirmText}}
                </button>
            </div>
        </div>
    </div>
</div>







