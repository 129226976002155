import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HTTP_INTERCEPTORS } from "@angular/common/http"
import { Injectable, Provider, inject } from "@angular/core"
import { finalize, of, take, } from "rxjs"
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastService } from "../services/toast.service"
import { TranslocoService } from "@ngneat/transloco"
import { HandlingService } from "../services/handling.service"
import { AuthService } from "app/core/auth/auth.service"
import { SnackbarService } from "../services/snackbar.service"
import { MatDialog } from "@angular/material/dialog"
import { Error503Component } from "../errors/error-503/error-503.component"
import { InternetConnectionService } from "../services/internet-connection.service"
import { RefreshPageComponent } from "../dialogs/refresh-page/refresh-page.component"
import Dexie from "dexie"
import { Utils } from "../others/utils"
import { Router } from "@angular/router"
import { LocalizeRouterService } from "@penleychan/ngx-transloco-router"
import { PremiumExtendDialogComponent } from "../dialogs/premium-extend-dialog/premium-extend-dialog.component";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private readonly _toastService: ToastService = inject(ToastService)
    private readonly _translocoService: TranslocoService = inject(TranslocoService)
    private readonly _handlingService: HandlingService = inject(HandlingService)
    private readonly _authService: AuthService = inject(AuthService)
    private readonly _snackbarService: SnackbarService = inject(SnackbarService)
    private readonly _dialog: MatDialog = inject(MatDialog);
    private readonly _ics: InternetConnectionService = inject(InternetConnectionService);
    private readonly _router: Router = inject(Router);
    private readonly _localize: LocalizeRouterService = inject(LocalizeRouterService);

    errorDialog
    _isErrorDialogVisible

    intercept(request: HttpRequest<any>, next: HttpHandler | any): any {

        return next.handle(request)
            .pipe(
                catchError(async (error: HttpErrorResponse) => {

                    

                    if (!this._ics.isOnline()) {
                        this._toastService.showToast({
                            icon: 'heroicons_solid:exclamation-triangle',
                            title: this._translocoService.translate('uwaga'),
                            message: this._translocoService.translate('brak_polaczenia_z_internetem'),
                            status: 'error',
                            timeToClose: 10000
                        })

                        next.handle(error)
                        return of(null)
                    }

                    if (typeof request.method == 'undefined') return next.handle(null)

                    this._handlingService.handleErrorsWithCustomActions(request, error)

                    // CORS 
                    if (error.status === 0 && request.method != 'GET') {
                        this._toastService.showToast({
                            icon: 'heroicons_solid:exclamation-triangle',
                            title: this._translocoService.translate('wystapil_blad'),
                            message: this._translocoService.translate('cors_error_info'),
                            status: 'error',
                            timeToClose: 10000
                        })

                        // let response = new HttpErrorResponse({
                        //     error: { status: 'error', errors: ['error1', 'error2']},
                        //     status: 422,
                        //     statusText: 'Not Found',
                        //     url: '/test/url'     
                        // });
                        // this._handlingService.handleResponse(response)

                        throw new HttpErrorResponse(error);
                    }

                    // Bad request error
                    else if (error.status === 400) {
                        this._toastService.showToast({
                            icon: 'heroicons_solid:exclamation-triangle',
                            title: this._translocoService.translate('wystapil_blad'),
                            message: this._translocoService.translate('bad_request_error_info'),
                            status: 'error',
                            timeToClose: 10000
                        })
                        throw new HttpErrorResponse(error);
                    }


                    // Unauthorized
                    // Do not proceed if error message is `TOKEN_EXPIRED` or during logout call
                    else if (error.status === 401) {

                        // Must propagate error to successfully logout
                        if (error.url.includes('logout')) {
                            throw new HttpErrorResponse(error);
                        }

                        const errorMessage = Utils.getNestedValue(error, "error.message")

                        // Disable to logout if it is a expired token
                        if (errorMessage == 'TOKEN_EXPIRED') return next.handle(request)

                        
                        const path = this._localize.translateRoute('/sign-out')
                        this._router.navigate([path])

                    }

                    // No permission error
                    else if (error.status === 403) {
                        this._snackbarService.showSnackBar(this._translocoService.translate('brak_uprawnien'), 'error', 2000);
                        const errorMessage = Utils.getNestedValue(error, "error.message")
                        this._dialog.open(PremiumExtendDialogComponent, {
                            data: {
                                reason: errorMessage
                            },
                            maxWidth: '100%'
                        });
                        throw new HttpErrorResponse(error);
                    }

                    // Not found?
                    else if (error.status === 404) {
                        this._toastService.showToast({
                            icon: 'heroicons_solid:exclamation-triangle',
                            title: this._translocoService.translate('wystapil_blad'),
                            message: this._translocoService.translate('not_found_error_info'),
                            status: 'error',
                            timeToClose: 10000
                        })
                        return next.handle(null)
                    }

                    // Form errors
                    // Displaying errors in dialog
                    else if (error.status === 422) {
                        this._toastService.showToast({
                            icon: 'heroicons_solid:exclamation-triangle',
                            title: this._translocoService.translate('wystapil_blad'),
                            message: this._translocoService.translate('form_error_info'),
                            status: 'error',
                            timeToClose: 10000
                        })

                        this._handlingService.handleResponse(error)
                        // Return the error to be handled by the component or service that initiated the request
                        throw new HttpErrorResponse(error);
                    }

                    // Internal server error
                    else if (error.status === 500) {
                        this._toastService.showToast({
                            icon: 'heroicons_solid:exclamation-triangle',
                            title: this._translocoService.translate('wystapil_blad'),
                            message: this._translocoService.translate('internal_server_error_info'),
                            status: 'error',
                            timeToClose: 10000
                        })
                        throw new HttpErrorResponse(error);
                    }

                    // Service unavailable
                    else if (error.status === 503) {
                        this._toastService.showToast({
                            icon: 'heroicons_solid:exclamation-triangle',
                            title: this._translocoService.translate('prace_techniczne'),
                            message: this._translocoService.translate('service_unavailable_error_info'),
                            status: 'error',
                            timeToClose: 10000
                        })

                        this._dialog.open(Error503Component, {
                            maxWidth: '100%'
                        });
                        throw new HttpErrorResponse(error);
                    }
                    // Synchronization needs error handling
                    throw new HttpErrorResponse(error);
                })
            )
    }
}

export const ErrorInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
}