import { Injectable, OnDestroy, inject } from "@angular/core";
import { Source } from "../models/Source";
import { AppDB } from "../models/db";
import { DbSource } from "../models/DbSource";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { TranslocoService } from "@ngneat/transloco";
import { Utils } from "../others/utils";
import { DbMeal } from "../models/DbMeal";

@Injectable({
  providedIn: 'root'
})
export class MealsService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _config = new BehaviorSubject<DbMeal>(new DbMeal())
  public currentConfig: Observable<DbMeal> = this._config.asObservable()

  private _db: AppDB

  // Clean subscriptions
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService,
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
          this._config.next(new DbMeal())
        }
      })
    )
    
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: async (data) => {
          if(data.table == 'meals') {
            this._config.next(data.value[0])
          }
        }
    }))
  }

  getConfig(): DbMeal {
    return this._config.value
  }


  getAll(): Observable<boolean> {

    if(!this._db) return of(true)
    this._config.next(new DbMeal());
    
    let startTime = 0

    const loader = from(this._db.transaction("r", this._db.meals, () => {
      startTime = performance.now();
      return this._db.meals.toArray();
    }))
    .pipe(
      map((meals: DbMeal[]) => {
        
        if(meals.length == 1) {
          this._config.next(meals[0]);
        }
        else {
          console.log(`[Meals Service]: Count: ${meals.length}`)
          this._config.next(new DbMeal());
        }
        return true;
      }),
      tap(() => console.log(`[Meals Service]: Built in ${performance.now() - startTime}ms.`))
    );

    return loader
  }

}
