import { HttpClientModule} from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideFuse } from '@fuse';
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { LOCALIZE_ROUTER_CONFIG, LocalizeRouterModule, localizeRouterConfig } from '@penleychan/ngx-transloco-router';
import { AppInitService } from './core/shared/services/app-init.service';
import { provideServices } from './core/shared/services/shared-services.provider';
import { CalendarImagesService } from './core/shared/services/calendar-images.service';
import { ErrorInterceptorProvider } from './core/shared/interceptors/error.interceptor';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginationIntlService } from './core/shared/services/mat-pagination-intl.service';
import { ApplicationData } from './core/shared/data/application-data';
import { ChannelManagerInterceptorProvider } from './core/shared/interceptors/channel-manager.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { OfflineModeInterceptorProvider } from './core/shared/interceptors/offline-mode.interceptor';
import { RefreshTokenInterceptorProvider } from './core/shared/interceptors/refresh-token.interceptor';

export const appConfig: ApplicationConfig = {
    providers: [

        provideAnimations(),
        provideAuth(),
        // Error handler
        importProvidersFrom(HttpClientModule),
        ErrorInterceptorProvider,
        RefreshTokenInterceptorProvider,
        OfflineModeInterceptorProvider,
        ChannelManagerInterceptorProvider,
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ),
        importProvidersFrom(LocalizeRouterModule.forRoot(appRoutes)),
        importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production })),
        // App initializator
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp1,
            deps: [AppInitService],
            multi: true
        },
        // Load calendar images
        {
            provide: APP_INITIALIZER,
            useFactory: loadCalendarImages,
            deps: [CalendarImagesService],
            multi: true
        },
        // Custom languages in mat paginator
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginationIntlService,
        },
        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },


        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: ApplicationData.AppLanugagesShort,
                defaultLang: 'pl',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            provide: LOCALIZE_ROUTER_CONFIG,
            useValue: localizeRouterConfig({
                translateRoute: false,
            })
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi: true,
        },
        // Fuse
        provideAuth(),
        provideServices(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                    {
                        id: 'theme-orange',
                        name: 'Orange',
                    },
                ],
            },
        }),
    ],
};

export function initializeApp1(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.initialize();
    }
}

export function loadCalendarImages(calendarImagesService: CalendarImagesService) {
    return (): Promise<any> => {
        return calendarImagesService.loadAll();
    }
}