<div class="relative flex flex-col min-h-full md:min-h-auto min-w-72 max-w-180 max-h-full sm:max-h-screen">
   
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'szczegoly' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-scroll">

            <channel-manager-import-notification-details *ngIf="notification.type == 6" [notification]="notification"></channel-manager-import-notification-details>
            <ical-notification-details *ngIf="notification.type == 7" [notification]="notification"></ical-notification-details>

        </div>
    </div>

    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-stroked-button mat-dialog-close class="me-2">
                    {{'zamknij' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>