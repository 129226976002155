import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { ApplicationData } from 'app/core/shared/data/application-data';
import { ApiService } from 'app/core/shared/services/api.service';
import { ResolverStateService } from 'app/core/shared/services/resolver-state.service';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs';

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    exportAs       : 'languages',
    standalone     : true,
    imports        : [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent implements OnInit, OnDestroy
{
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
    */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _localizeRouterService:LocalizeRouterService,
        private _activatedRoute:ActivatedRoute,
        private _dateAdapter: DateAdapter<Date>, 
        private _apiService:ApiService,
        private _router: Router,
        private _resolverStateService: ResolverStateService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the available languages from transloco
        this.availableLangs = ApplicationData.Languages.map(x => { return {id: x.shortName, label: x.name}});

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe({
            next: (activeLang) =>
            {
                // Get the active lang
                this.activeLang = activeLang;
                // Update app locale
                this._dateAdapter.setLocale(activeLang);
                // Update the navigation
                // this._updateNavigation(activeLang);
        

        }});

        // Set the country iso codes for languages for flags
        this.flagCodes = ApplicationData.AppFlagCodes
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void
    {
        this._resolverStateService.disableResolver()
        // this._translocoService.setActiveLang(lang);
        this._localizeRouterService.changeLanguage(lang, { skipLocationChange: true})
        // Save user default lanugage
        this._saveUserLanugage(lang)

        setTimeout(()=> {
            this._resolverStateService.enableResolver()
        }, 1000)
      
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _saveUserLanugage(lang) {
        this._apiService.changeLanguage(lang)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
           
         
          }
        })
      }
    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void
    {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if ( !navComponent )
        {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Project dashboard item and update its title
        const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        if ( projectDashboardItem )
        {
            this._translocoService.selectTranslate('Project').pipe(take(1))
                .subscribe((translation) =>
                {
                    // Set the title
                    projectDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Analytics dashboard item and update its title
        const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        if ( analyticsDashboardItem )
        {
            this._translocoService.selectTranslate('Analytics').pipe(take(1))
                .subscribe((translation) =>
                {
                    // Set the title
                    analyticsDashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
    }
}
