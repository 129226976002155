import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from './api.service';
import { SettingsService } from './settings.service';
import { InternetConnectionService } from './internet-connection.service';
import { PermissionsService } from './permissions.service';
import { SynchronizationService } from './synchronization.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserSettings } from '../models/UserSettings';
import { Subscription, take } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { Utils } from '../others/utils';
import { ApplicationData } from '../data/application-data';
import { SnackbarService } from './snackbar.service';
import { UserPermissions } from '../models/UserPermissions';
import { RefreshPageComponent } from '../dialogs/refresh-page/refresh-page.component';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { Router } from '@angular/router';
import { DbService } from './db.service';
import { DbUserSettingsService } from './db-user-settings.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { CollisionService } from './collision.service';
import { decodeToken } from '../utils/jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AppInitService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _isErrorDialogVisible:boolean = false
  errorDialog = null

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  constructor(
    private settingsService: SettingsService,
    private _dbUserSettingsService:DbUserSettingsService,
    private _apiService: ApiService,
    private _authService: AuthService,
    private _dbService: DbService,
    private ics: InternetConnectionService,
    private permissionsService: PermissionsService,
    private synchronizationService:SynchronizationService,
    private translate:TranslocoService,
    private snackbar:SnackbarService,
    private _localize:LocalizeRouterService,
    private _router:Router,
    private _dialogRef: MatDialog,
    private _fuseSplashScreenService:FuseSplashScreenService,
    private _collisionService: CollisionService

  ) 
  {
    this._subscriptions.push(
      this.ics.getOnlineStatus()
        .subscribe({
          next: (data) => {
            if (data == true && this.errorDialog != null) {
              this.errorDialog.close()
              this.ics.offlineModeOff()
            }
          }
        }))
  }

  initialize(redirect = false) {

    this.detectAndCheckLanguage()
    return new Promise<void>((resolve, reject) => {

      Utils.generateDeviceId();

      if (!this._authService.isLoggedIn()) {
        resolve();
        return;
      }


      this._apiService.refreshSettings()
        .pipe(take(1))
        .subscribe({
          next: async (response: IInitRespone) => {
            console.info("[AppInit]: Running app initializer.")
  
            // Clean collisions
            this._collisionService.clean()

            let employeeId = null
            if(Utils.isDefined(response,"data.userData.employeeId")) {
              employeeId = response?.data?.userData?.employeeId
            }
           
            // Prepare database
            await this._dbService.setDatabase(response.data.userData.userId, employeeId)
            
            // Run synchronization
            this.synchronizationService.newSynchronize("[Database Service]: Synchronize from Database Service");
            
            // Set user language
            let lang = response.data.userData.lang
            if(!Utils.isNullOrEmpty(lang)) {
              this.translate.setActiveLang(response.data.userData.lang)
              this._localize.changeLanguage(lang)
            }
            
            // Update user settings
            this.settingsService.updateUserSettings(response.data)
            // Save user settings to database
            // This features allows to get last settings for offline mode
            this._dbUserSettingsService.saveUserSettings(response.data)
            // Update permissions
            if(Utils.isDefined(response,"data.permissions")) this.permissionsService.permissions.next(new UserPermissions(response.data.permissions))
            else this.permissionsService.permissions.next(new UserPermissions({}))

            if(redirect == true) {
              const trasnlatedPath = this._localize.translateRoute('/app')
              this._router.navigate([trasnlatedPath]);
            }

            // Check premium time
            const premiumDays = Utils.checkPremiumDays(response.data.userData.premiumTime)
            if(premiumDays <= 0) {
                console.log(`[AppInitService]: No premium days left. Redirecting...`)
                const translatedPath = this._localize.translateRoute('/app/subscription/expired');
                this._router.navigate([translatedPath]);
                resolve()
                return
            }

           
      
            resolve()
            return
          },
          error: (error: HttpErrorResponse) => {

            console.log("[AppInit]: Error. Can't get user settings.")
            this.snackbar.showSnackBar(this.translate.translate('cos_poszlo_nie_tak'), this.translate.translate('blad'));

            console.log("ERROR: ", error)
            // If response from API is 401 = you can't access next step (offline mode)
            if (error.status == 401) {

              // If user was logged in redirect and logout user
              if(this._authService.isLoggedIn()) {
                const translatedPath = this._localize.translateRoute('/sign-out');
                this._router.navigate([translatedPath]);
              }
              // If user is not logged in, just redirect to sign in
              else {
                const translatedPath = this._localize.translateRoute('/sign-in');
                this._router.navigate([translatedPath]);
              }
             
              resolve();
              return;
            }

            // Do not show dialog if dialog ref is already displayed
            if (this._isErrorDialogVisible == true) return;

            // // Show safe mode
            this.errorDialog = this._dialogRef.open(RefreshPageComponent, {
              width: '350px',
              maxWidth: '100%',
              disableClose: true,
            });
            
            this._isErrorDialogVisible = true
            this._fuseSplashScreenService.hide()
            this.errorDialog.afterClosed().subscribe(
              async (data) => {

                this._isErrorDialogVisible = false
                if (typeof data == 'undefined') {
                  resolve();
                  return;
                }

                if (typeof data.offlineMode == 'undefined') {
                  resolve();
                  return;
                }

                const { userId, employeeId } = decodeToken(this._authService.token)

                await this._dbService.setDatabase(userId, employeeId);
                this.synchronizationService.newSynchronize("[Database Service]: Synchronize from Database Service");

                const settings: any = await this._dbUserSettingsService.getUserSettings()
                this.settingsService.updateUserSettings(settings);
                resolve();
                this.errorDialog = null
                return
              }
            )
          }
        })
    })
  }

  detectAndCheckLanguage() {
    // Get user's default language from the browser
    const userLanguage:any = navigator.language || 'en'
  
    // Check if the user's language is in the array
    const foundLanguage = ApplicationData.Languages.find(language => language.shortName === userLanguage);
  
    if (foundLanguage) {
      console.log("foundLanguage", foundLanguage)
      localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE',foundLanguage.shortName)
      localStorage.setItem('lang',foundLanguage.shortName)
      this.translate.setActiveLang(foundLanguage.shortName)
    }
    
  }

}

export interface IInitRespone extends IAPIResponse {
  data: UserSettings
}

export class IAPIResponse {
  status: 'success' | 'error'
  data: any
}