import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { debounceTime, finalize, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { UntypedFormBuilder, FormControl, Validators, FormArray, Form, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ReservationConfirmationDialogComponent } from '../reservation-confirmation-dialog/reservation-confirmation-dialog.component';
import { AuthService } from 'app/core/auth/auth.service';
import { ApplicationData } from '../../data/application-data';
import { CONST } from '../../data/const';
import { priceValidator } from '../../directives/validators.directive';
import { DbAdditionalService } from '../../models/DbAdditionalService';
import { DbCurrency } from '../../models/DbCurrency';
import { DbEmployee } from '../../models/DbEmployee';
import { DbLogs } from '../../models/DbLogs';
import { DbInvoice } from '../../models/DbInvoice';
import { DbReservation } from '../../models/DbReservation';
import { DbRoom } from '../../models/DbRoom';
import { IAdditionalService, ReservationPricingSummary } from '../../models/ReservationPricingSummary';
import { Source } from '../../models/Source';
import { UserPermissions } from '../../models/UserPermissions';
import { UserSettings } from '../../models/UserSettings';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { Utils } from '../../others/utils';
import { ApiService } from '../../services/api.service';
import { AvailabilityService } from '../../services/availability.service';
import { CalendarNavigationService } from '../../services/calendar-navigation.service';
import { DataChangeTrackingService } from '../../services/data-change-tracking.service';
import { DialogService } from '../../services/dialog.service';
import { HandlingService } from '../../services/handling.service';
import { InvoicesService } from '../../services/invoices.service';
import { PanelService } from '../../services/panel.service';
import { PermissionsService } from '../../services/permissions.service';
import { PricingService } from '../../services/pricing.service';
import { SettingsService } from '../../services/settings.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SynchronizationService } from '../../services/synchronization.service';
import { ConfigureGroupPricesComponent } from '../configure-group-prices/configure-group-prices.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SourceManageComponent } from '../../components/source-manage/source-manage.component';
import { PaymentLinkComponent } from '../../components/payment-link/payment-link.component';
import { ReservationHistoryComponent } from '../../components/reservation-history/reservation-history.component';
import { ShowReservationBookingDataComponent } from '../../components/show-reservation-booking-data/show-reservation-booking-data.component';
import { MatCalendarCellClassFunction, MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatColorPickerComponent, NgxMatColorToggleComponent, Color } from '@angular-material-components/color-picker';
import { ShowOnFormComponent } from '../../components/show-on-form/show-on-form.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, JsonPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { NgxColorsModule } from 'ngx-colors';
import { MatChipsModule } from '@angular/material/chips';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { ClientRowComponent } from 'app/core/shared/components/client-row/client-row.component';
import { MatSearchSelectComponent } from '../../components/mat-search-select/mat-search-select.component';
import { SourcesService } from '../../services/sources.service';
import { DbService } from '../../services/db.service';
import { AppDB } from '../../models/db';
import { CurrenciesService } from '../../services/currencies.service';
import { AdditionalServicesService } from '../../services/additional-services.service';
import { cloneDeep, isBoolean } from 'lodash';
import { DateTime } from 'luxon';
import { ClientsService } from '../../services/clients.service';
import { EmployeesService } from '../../services/employees.service';
import { RoomsService } from '../../services/rooms.service';
import { DbClient } from '../../models/DbClient';
import { clientFilter } from '../../filters/client.filter';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { fuseAnimations } from '@fuse/animations';
import { ReplaceCommaByDotDirective } from '../../directives/replace-comma-by-dot.directive';
import { ReplaceCommaByDotNoControlDirective } from '../../directives/replace-comma-by-dot-no-control.directive';
import { FuseAlertComponent } from '@fuse/components/alert';
import { CalculatePricesDialogComponent } from '../calculate-prices-dialog/calculate-prices-dialog.component';
import { PaymentStatuses } from '../../models/payment-statuses.enum';
import { ShowReservationBookingDataDialogComponent } from '../show-reservation-booking-data-dialog/show-reservation-booking-data-dialog.component';

@Component({
  standalone: true,
  selector: 'app-add-reservation-tabs-dialog',
  templateUrl: './add-reservation-tabs-dialog.component.html',
  styleUrls: ['./add-reservation-tabs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, ReplaceCommaByDotDirective, ReplaceCommaByDotNoControlDirective, RouterModule, MatBadgeModule, MatTooltipModule, LocalizeRouterModule, MatFormFieldModule, MatDatepickerModule, MatSelectModule, MatCheckboxModule, MatListModule, ReactiveFormsModule, MatTabsModule, TranslocoModule, MatSlideToggleModule, MatButtonModule, MatRadioModule, MatChipsModule, NgxColorsModule, NgxMatTimepickerModule, MatSearchSelectComponent, MatInputModule, MatAutocompleteModule, ScrollingModule, MatRippleModule, MatMenuModule, ClientRowComponent, FuseAlertComponent],
  animations : fuseAnimations
})
export class AddReservationTabsDialogComponent implements OnDestroy {

  selectedRooms = [];

  kidsCount = [];
  alertData = {
    title: this.translate.translate('anulowana_rezerwacja'),
    status: 'warning',
    message: this.translate.translate('rezerwacja_anulowana_info'),
    canDismiss: false
  }

  processing = false;

  colorPickerControls: "default" | "only-alpha" | "no-alpha" = "no-alpha";
  mode: 'create' | 'update' = 'create';
  subscriptions: Subscription[] = [];
  rooms: DbRoom[] = [];
  employees = {};
  clients = {};
  userSettings: UserSettings;

  registrationTypes = ApplicationData.RegistrationTypes

  types = ApplicationData.ReservationTypes
  clientTypes = ApplicationData.ClientTypes

  selectedPaymentStatus = null;

  discountTypes = [
    { id: 0, text: '%' },
    { id: 1, text: '' },
  ]

  alimentations = ApplicationData.AlimentationTypes
  reservationPaymentStatuses = cloneDeep(ApplicationData.ReservationPaymentStatuses)
  paymentTypes = ApplicationData.ReservationPaymentTypes
  @ViewChild('matColorToggle') matColorToggle: NgxMatColorToggleComponent;
  @ViewChild('colorPicker') colorPicker: NgxMatColorPickerComponent;
  @ViewChild('prepaymentDeadline') prepaymentDeadline: MatDatepicker<any>;

  show = {
    more_client_data: false,
    meals: false,
    summary: true,
    discount: false
  }

  ages = ApplicationData.KidsAges;

  countries = ApplicationData.Countries.map((c) => {
    return {
      id: c.id,
      name: this.translate.translate('country_' + c.id)
    }
  });

  languages = ApplicationData.Languages

  priceChangesDetector = false;
  sources: Source[] = [];
  currencies: DbCurrency[] = [];
  additionalServices: DbAdditionalService[] = [];

  default_currency = "PLN";

  addReservationForm = this.formBuilder.group({
    type: new FormControl(0, Validators.required),
    roomId: new FormControl(null, Validators.required),
    // traktowanie: new FormControl(0, Validators.required),
    arrival: new FormControl('', Validators.required),
    departure: new FormControl('', Validators.required),
    checkIn: new FormControl('16:00'),
    checkOut: new FormControl('10:00'),
    adults: new FormControl(1),
    children: new FormControl(0),

    clientId: new FormControl(null),
    name: new FormControl('', Validators.required),
    forename: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl('', Validators.email),
    idCard: new FormControl(''),
    taxId: new FormControl(''),
    companyName: new FormControl(''),
    personalId: new FormControl(''),
    vehicleRegistrationNumber: new FormControl(''),
    address: new FormControl(''),
    comments: new FormControl(''),
    countryId: new FormControl(''),
    countryName: new FormControl(''),
    language: new FormControl(''),
    clientType: new FormControl(0),

    color: new FormControl(''),
    colorPckr: new FormControl(''),
    registration: new FormControl(0),
    sourceId: new FormControl(0),
    doorCode: new FormControl(''),
    additionalInfo: new FormControl(''),
    pricePerRoomPerNight: new FormControl(0, [priceValidator()]),
    days: new FormControl(1),
    pricePerRoom: new FormControl('', [priceValidator()]),
    meal: new FormControl(0),
    pricePerMeal: new FormControl(0),
    adultsPortion: new FormControl(0),
    childrenPortion: new FormControl(0),
    additionalServices: new FormArray([]),
    ownColor: new FormControl(false),
    currency: new FormControl(this.default_currency),
    prepayment: new FormControl(0),
    paymentStatus: new FormControl(0),
    paymentType: new FormControl(0),
    prepaymentDeadline: new FormControl(null),
    discount: new FormControl(0),
    discountType: new FormControl(0),
    status: new FormControl('0'),
  });

  formFields = []
  res:DbReservation | any = {}
  reservation: DbReservation;

  timeFormat: 12 | 24 = 24
  dayInMilliseconds = CONST.DAYINMILLIS;
  //public dialogRef: MatDialogRef<AddReservationDialogComponent>, @Optional()
  reservationAdditionalServices:IAdditionalService[] = [];

  pricingSummary = new ReservationPricingSummary(
    this.default_currency,
    this._additionalServicesService,
    this.translate.translate('brak_wplaty')
  );

  paymentStatuses = this.buildPaymentStatuses();
  settings;
  isReservationFromBookingCom = false;

  additionalServicePaymentStatuses = [
    { id: 0, translation: 'brak_wplaty' },
    { id: 1, translation: 'zaplacono' },
  ]
  // History
  // objectHistory:ObjectHistory;
  latestChange: DbLogs;
  creator;

  invoices: DbInvoice[] | any = [];
  permissions: UserPermissions = new UserPermissions({})

  // Fields to monitor for automatic prepayment calculation
  automaticPrepaymentFieldsMonitor = [
    'roomId','arrival','departure','adults','children','discount', 'discountType', 'pricePerRoom', 'pricePerRoomPerNight', 'pricePerMeal', 'additionalServices'
  ]
  constructor(
    @Inject(DATA_INJECTION_TOKEN) public data: any,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private _snackbarService: SnackbarService,
    public translate: TranslocoService,
    private panelService: PanelService,
    private settingsService: SettingsService,
    private dialogService: DialogService,
    private calendarNavigationService: CalendarNavigationService,
    private invoicesService: InvoicesService,
    private handlingService: HandlingService,
    private availabilityService: AvailabilityService,
    private permissionsService: PermissionsService,
    private _synchronizationService: SynchronizationService,
    private _dataChangeTrackingService: DataChangeTrackingService,
    private pricingService: PricingService,
    private _sourcesService: SourcesService,
    private _currenciesService: CurrenciesService,
    private _additionalServicesService: AdditionalServicesService,
    private _clientsService: ClientsService,
    private _employeesService: EmployeesService,
    private _roomsService: RoomsService,
    private _changeDetectorRef:ChangeDetectorRef
  ) {

    this.subscriptions.push(this._sourcesService.currentSources.subscribe({ next: (data) => this.sources = data }))
    this.subscriptions.push(this._currenciesService.currentCurrencies.subscribe({ next: (data) => this.currencies = data }))
    this.subscriptions.push(this._additionalServicesService.currentAdditionalServices.subscribe({ next: (data) => this.additionalServices = data }))

    this.subscriptions.push(this.permissionsService.currentPermissions.subscribe(
      (data) => {
        this.permissions = data
        if(!this.permissions.has('editReservation')) {
          this.addReservationForm.disable()
        }
      }
    ))

    // Monitor changes in fields to automatically update prepayment value
    this.automaticPrepaymentFieldsMonitor.forEach(key=> {
      this.addReservationForm.controls[key].valueChanges.subscribe({
        next: (data)=> {
          setTimeout(()=> {
            this._calculatePrepaymentAmount()
          },100)
        }
      })
    })

    // Testing purposes only
    // this.subscriptions.push(this.addReservationForm.controls.roomId.valueChanges.subscribe({
    //   next: (data) => {
    //     console.log(data, typeof data)
    //     console.log("available rooms", this.availableRooms)
    //   }
    // }))

    this.subscriptions.push(this.addReservationForm.controls.type.valueChanges.subscribe({
      next: (type) => {
        if (this.mode == 'create') {
          const roomId = this.addReservationForm.controls.roomId.value
          if (roomId == null || typeof roomId == 'undefined') return

          if (type == 0) {
            if (Array.isArray(roomId) && roomId.length > 0) {
              this.addReservationForm.controls.roomId.setValue(roomId[0])
              this.updateSelectedRooms()
            }
          }
        }
      }
    }))

    this.subscriptions.push(this.addReservationForm.controls.type.valueChanges.subscribe(
      {
        next: (data) => {

          const rooms = this.addReservationForm.controls.roomId.value

          if (data == 2 || data == '2') {

            this.addReservationForm.controls.name.removeValidators(Validators.required)
            this.addReservationForm.controls.pricePerRoomPerNight.removeValidators(priceValidator())
            this.addReservationForm.controls.pricePerRoom.removeValidators(priceValidator())
          }
          else {
            this.addReservationForm.controls.name.addValidators(Validators.required)
            this.addReservationForm.controls.pricePerRoomPerNight.addValidators(priceValidator())
            this.addReservationForm.controls.pricePerRoom.addValidators(priceValidator())

            // Sprawdz czy nie przekracza maksymalnej wartości
            if (this.addReservationForm.controls.children.value > 20) {
              this.addReservationForm.controls.children.setValue(20)
            }
          }

          this.addReservationForm.controls.name.updateValueAndValidity();
          this.addReservationForm.controls.pricePerRoomPerNight.updateValueAndValidity();
          this.addReservationForm.controls.pricePerRoom.updateValueAndValidity();

          // Aktualizacja wyboru pokoju
          if (data == 0 || data == '0') {
            // this.addReservationForm.controls.room.addValidators(Validators.required)
            // this.addReservationForm.controls.rooms.removeValidators(Validators.required)
          }
          else if (data == 1 || data == '1') {

            // this.addReservationForm.controls.room.removeValidators(Validators.required)
            // this.addReservationForm.controls.rooms.addValidators(Validators.required)
          }
          else if (data == 2 || data == '2') {
            if (this.mode == 'create') {
              // this.addReservationForm.controls.room.removeValidators(Validators.required)
              // this.addReservationForm.controls.rooms.addValidators(Validators.required)
            }
            else if (this.mode == 'update') {
              // this.addReservationForm.controls.room.addValidators(Validators.required)
              // this.addReservationForm.controls.rooms.removeValidators(Validators.required)
            }
          }
          // this.addReservationForm.controls.room.updateValueAndValidity();
          // this.addReservationForm.controls.rooms.updateValueAndValidity();
          // Aktualizacja kontrolek
          // Rezerwacja grupowa
          // Nie powinna zawierać informacji na temat wieku dzieci.
          if (data == 1 || data == '1') {
            this.updateKidsFormControls(0)
          }
          else {
            // if (this.addReservationForm.controls.traktowanie.value == '1') {
            //   this.addReservationForm.controls.traktowanie.setValue('0')
            // }
            this.updateKidsFormControls(parseInt(this.addReservationForm.controls.children.value))
          }



          // Przy tworzeniu nowej rezerwacji
          // Jezeli zmienia się na zwykłą rezerwację to ustaw automatycznie pierwszy wybrany z poprzedniej tablicy
          if (this.mode == 'create') {

            // Jezeli pokoje sa tablica
            if (data == 0 || data == '0') {
              if (Array.isArray(rooms)) {
                if (rooms.length > 0) {
                  this.addReservationForm.controls.roomId.setValue(rooms[0])
                }
              }
            }


            // Clear values
            // this.addReservationForm.controls.rooms.setValue(null)
            // }
            // Jezeli grupowa lub blokada
            // else if (
            // typeof this.addReservationForm.controls.rooms != 'undefined' &&
            // this.addReservationForm.controls.rooms.value.length == 0 && !Utils.isNullOrEmpty(this.addReservationForm.controls.room.value)) {
            // const rooms = this.addReservationForm.controls.rooms.value || [];
            // rooms.push(this.addReservationForm.controls.room.value);
            // this.addReservationForm.controls.rooms.setValue(rooms)
            // Clear value 
            // this.addReservationForm.controls.room.setValue(null)

            // }
          }
        }
      }
    ))

    this.subscriptions.push(this.settingsService.getUserSettings().subscribe({

      next: (userSettings:UserSettings) => {

        this.userSettings = userSettings;
        if (Utils.isDefined(userSettings, "settings")) {
          this.settings = userSettings.settings;

        }
  
        if (Utils.isDefined(userSettings, "userData.currency")) {
          this.default_currency = userSettings.userData.currency;
        }
  
        if (Utils.isDefined(userSettings, "settings.reservationField")) {
          this.formFields = userSettings.settings.reservationField;
        }
  
        if(userSettings.settings.generalTimeFormat.includes('A')) {
          this.timeFormat = 12
        } else this.timeFormat = 24

        this._changeDetectorRef.markForCheck()
      }

    }))

    this.subscriptions.push(this._clientsService.currentClients.subscribe({
      next: (data) => this.clients = data
    }))

    this.subscriptions.push(this._employeesService.currentEmployees.subscribe({
      next: (data) => this.employees = data
    }))

    // Load data first
    this.subscriptions.push(this._roomsService.currentRooms.subscribe(
      (data) => {
        this.rooms = Object.entries(data)
          .sort((a: any, b: any) => a[1].order - b[1].order)
          .filter(
            (x: any) => {

              if (x[1].status != 0) return true;
              return false;

            }
          )
          .map((x: any) => x[1]);

        // this.getInvoices()
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.paymentStatus.valueChanges.subscribe({
        next: (data) => {

          this.selectedPaymentStatus = this.reservationPaymentStatuses.find(x => x.id == data)

          // Disable change of payment status for Booking Engine 
          if(this.mode == 'update' && this.reservation.paymentStatus == 3) {
            
            this.addReservationForm.controls.paymentStatus.setValue(3, { emitEvent: false })
            this.addReservationForm.controls.paymentStatus.disable({ emitEvent: false })
            return
          }

          this.pricingSummary.setReservationStatus(data);
          this.selectedPaymentStatus = this.reservationPaymentStatuses.find(x => x.id == data)

          if (data == 'waitingForPrepayment') {
            this.addReservationForm.controls.prepaymentDeadline.setValidators(Validators.required)
            if (!Utils.isDateSet(this.addReservationForm.controls.prepaymentDeadline.value)) {
              if (typeof this.prepaymentDeadline != 'undefined') {
                this.prepaymentDeadline.open();
              }
            }
          } 
          else {
            this.addReservationForm.controls.prepaymentDeadline.removeValidators(Validators.required)
          }

          // If no patment status
          if (data == 0) {
            this.addReservationForm.controls.prepaymentDeadline.setValue(null)
          }

          this.addReservationForm.controls.prepaymentDeadline.updateValueAndValidity({ emitEvent: false })
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.prepayment.valueChanges.subscribe(
      {
        next: (data) => {
          this.pricingSummary.setAdvancePayment(parseFloat(data));
        }
      }
    ))


    // Count days on change
    this.subscriptions.push(this.addReservationForm.controls.departure.valueChanges.subscribe(
      {
        next: (data) => {
          if(data == null) return
          if(data.isLuxonDateTime) {
            if(data.ts == this.addReservationForm.controls.arrival.value.ts) {
              this.addReservationForm.controls.departure.setValue(DateTime.fromMillis(this.addReservationForm.controls.arrival.value.ts + CONST.DAYINMILLIS) )
            }
          }
          

          if (Utils.isNullOrEmpty(this.addReservationForm.controls.departure.value)) return;

          let days = (new Date(data).getTime() - new Date(this.addReservationForm.controls.arrival.value).getTime()) / this.dayInMilliseconds;
          days = Math.round(days);
          this.addReservationForm.controls.days.setValue(days, { emitEvent: false });

          this.browseAvailableRooms()
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.pricePerRoomPerNight.valueChanges.subscribe(
      {
        next: (data) => {
          if (data >= 0) {
            this.addReservationForm.controls.pricePerRoom.setValue(parseFloat((data * this.addReservationForm.controls.days.value).toFixed(2)), { emitEvent: false })
            this.pricingSummary.setPricePerRoom(this.addReservationForm.controls.pricePerRoom.value);
          }
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.pricePerRoom.valueChanges.subscribe(
      {
        next: (data) => {
          if (data >= 0) {
            this.addReservationForm.controls.pricePerRoomPerNight.setValue(parseFloat((data / this.addReservationForm.controls.days.value).toFixed(2)), { emitEvent: false })
            this.pricingSummary.setPricePerRoom(data);
          }
        }
      }
    ))


    // this.subscriptions.push(this.addReservationForm.controls.type.valueChanges.subscribe(
    //   {
    //     next: (data) => {
    //       if (data >= 0) {
    //         if(this.addReservationForm.controls.pricePerRoomPerNight.value > 0) {
    //           this.addReservationForm.controls.pricePerRoomPerNight.setValue(parseFloat((this.addReservationForm.controls.pricePerRoomPerNight.value / this.addReservationForm.controls.days.value).toFixed(2)), { emitEvent: false })
    //           this.pricingSummary.setPricePerRoom(data);
    //         }
    //       }
    //     }
    //   }
    // ))

    // Liczba dni
    this.subscriptions.push(this.addReservationForm.controls.days.valueChanges.subscribe(
      {
        next: (data) => {
          if (Number.isNaN(data) || Utils.isNullOrEmpty(data)) { data = 1; this.addReservationForm.controls.days.setValue(1, { emitEvent: false }) }
          if (typeof data == 'string') { data = parseInt(data) }
          if (data >= 0) {

            // Set departure
            this.addReservationForm.controls.departure.setValue(DateTime.fromMillis(this.addReservationForm.controls.arrival.value.ts + (data * this.dayInMilliseconds)))
            // While updating show a button to auto calc 
            // Only for reservations, exclude locks
            // Not deleted
            if(this.mode == 'update' && this.addReservationForm.controls.type.value != 2 && this.reservation.status == 1) {
              this.priceChangesDetector = true
            } 
            // while creating autocalculate
            else {
              const pricePerRoomPerNight = this.addReservationForm.controls.pricePerRoomPerNight.value
              this.addReservationForm.controls.pricePerRoom.setValue(parseFloat((pricePerRoomPerNight * data).toFixed(2)), { emitEvent: false })
              this.pricingSummary.setPricePerRoom(this.addReservationForm.controls.pricePerRoom.value);
            }
            
          }
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.pricePerMeal.valueChanges.subscribe(
      {
        next: (data) => {
          if (data >= 0) {
            this.pricingSummary.setFoodPrice(data);
          }
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.roomId.valueChanges.subscribe(
      {
        next: (data) => {
          if (typeof data == 'undefined') return;
          this.updateSelectedRooms();
        }
      }
    ))

    // For showing errors in tabs
    Object.keys(this.controlTabs).forEach((key) => {
      this.subscriptions.push(this.addReservationForm.controls[key].valueChanges.subscribe(
        {
          next: (data) => {
            const tabToReset = this.controlTabs[key];
            if (typeof tabToReset != 'undefined') this.errors[tabToReset] = false;
            this.processFormErrors(Utils.getFormControlValidationErrors(this.addReservationForm, key, true))
          }
        }
      ))
    })

    this.subscriptions.push(this.addReservationForm.controls.children.valueChanges.subscribe(
      {
        next: (data) => {
          if (this.addReservationForm.controls.type.value == 1) { return }

          if (Number.isNaN(data) || Utils.isNullOrEmpty(data)) { data = 0; this.addReservationForm.controls.children.setValue(0, { emitEvent: false }) }
          if (data >= 0) {
            if (data > 20) { data = 20; this.addReservationForm.controls.children.setValue(20, { emitEvent: false }) }

            this.updateKidsFormControls(data)

          }

          this.kidsCount = [];
          for (let i = 1; i <= this.addReservationForm.controls.children.value; i++) {
            this.kidsCount.push(i);
          }
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.adults.valueChanges.subscribe(
      {
        next: (data) => {
          if (Number.isNaN(data) || Utils.isNullOrEmpty(data)) { data = 1; this.addReservationForm.controls.adults.setValue(1, { emitEvent: false }) }
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.discount.valueChanges.subscribe(
      {
        next: (data) => {
          if (data >= 0) {
            const controlValue = this.addReservationForm.controls.discountType.value;
            if (controlValue == 0 && data > 100) {
              data = 100
              this.addReservationForm.controls.discount.setValue(100, { emitEvent: false })
              return;
            }
            if (typeof controlValue != 'undefined' && (controlValue == 0 || controlValue == 1)) {
              this.pricingSummary.setDiscount(this.show.discount, data, parseFloat(this.addReservationForm.controls.discountType.value));
            }
          }
        }
      }
    ))

    this.subscriptions.push(this.addReservationForm.controls.discountType.valueChanges.subscribe(
      {
        next: (data) => {
          if (data >= 0) {
            const controlValue = this.addReservationForm.controls.discount.value;
            if (typeof controlValue != 'undefined' && controlValue >= 0) {
              this.pricingSummary.setDiscount(this.show.discount, controlValue, parseFloat(data));
            }
          }
          if (data == "1" && this.addReservationForm.controls.discount.value > 100) {
            this.addReservationForm.controls.discount.setValue(100)
            return;
          }
        }
      }
    ))



    this.subscriptions.push(this.addReservationForm.controls.countryName.valueChanges.subscribe(
      (data) => {
        if (data != null) {
          this.browseCountries(data);
        }
      }
    ))

    // Check mode
    if (typeof data.mode == 'undefined') {
      throw new Error('Mode not set.');
    }

    this.mode = data.mode;
    this.reservation = data.reservation;
    this.res = data.reservation

    // Remove payment status from booking engine when reservation doesnt have it.
    if(this.reservation.paymentStatus != 3) {
      this.reservationPaymentStatuses = this.reservationPaymentStatuses.filter(x=>x.id != 3)
    }

    // Edit reservation or blockade
    if (this.mode == 'update') this.prepareFormForUpdatingReservation(data)
    else if (this.mode == 'create') this.prepareFormForCreatingReservation(data)

  }

  private _calculatePrepaymentAmount() {
    if(!!this.userSettings.settings.reservationAutomaticPrepaymentAmountTurnOn) {
        const type = this.userSettings.settings.reservationAutomaticPrepaymentAmountType

        let prepaymentValue = 0
        if(type == 0) {
          const value = parseFloat(this.userSettings.settings.reservationAutomaticPrepaymentAmountPrice)
          prepaymentValue = (this.pricingSummary.summary * (value / 100))
        } else prepaymentValue = parseFloat(this.userSettings.settings.reservationAutomaticPrepaymentAmountPrice)

        prepaymentValue = parseFloat(prepaymentValue.toFixed(2))
        if(this.mode == 'create') { this.addReservationForm.controls.prepayment.setValue(prepaymentValue)}
       
      }
  }

  prepareFormForUpdatingReservation(data) {
    if (typeof data.reservation != 'undefined') {
      this.reservation = data.reservation;

      // Update pricing summary from reservation data
      this.pricingSummary.fromReservation(this.reservation)

      // TODO
      // this.getInvoices()
      this.excluded = [this.reservation.reservationId]
      // TODO
      // this.getLatestChange()
      if (Utils.isDefined(this.reservation, "booking_details")) {
        this.isReservationFromBookingCom = true;
      }

      const type = this.setReservationType(this.reservation.roomId);
      this.addReservationForm.controls.type.setValue(type);
      this.addReservationForm.controls.roomId.setValue(this.reservation.roomId);


      this.addReservationForm.controls.arrival.setValue(DateTime.fromJSDate(new Date(this.reservation.arrival)));
      this.addReservationForm.controls.departure.setValue(DateTime.fromJSDate(new Date(this.reservation.departure)));
      this.addReservationForm.controls.checkIn.setValue(this.reservation.checkIn.slice(0, 5));
      this.addReservationForm.controls.checkOut.setValue(this.reservation.checkOut.slice(0, 5));

      this.browseAvailableRooms()

      this.addReservationForm.controls.adults.setValue(this.reservation.adults);
      this.addReservationForm.controls.children.setValue(this.reservation.children);

      this.updateKidsFormControls(parseInt(this.addReservationForm.controls.children.value));

      // Client
      this.addReservationForm.controls.clientId.setValue(this.reservation.clientId);
      if (Utils.isDefined(this.reservation, "client")) {

        if (this.reservation.status == 0) {
          this.addReservationForm.disable();
        }

        this.addReservationForm.controls.forename.setValue(this.reservation.client.forename);
        this.addReservationForm.controls.name.setValue(this.reservation.client.name);
        this.addReservationForm.controls.phone.setValue(this.reservation.client.phone);
        this.addReservationForm.controls.email.setValue(this.reservation.client.email);
        this.addReservationForm.controls.idCard.setValue(this.reservation.client.idCard);
        this.addReservationForm.controls.taxId.setValue(this.reservation.client.taxId);
        // Is optional, but its required in request so must be empty string || ''
        this.addReservationForm.controls.companyName.setValue(this.reservation.client.companyName || '');
        this.addReservationForm.controls.personalId.setValue(this.reservation.client.personalId);
        this.addReservationForm.controls.language.setValue(this.reservation.client.language);

        // Narodowosc
        if (Utils.isDefined(this.reservation, "client.countryId")) {

          this.addReservationForm.controls.countryId.setValue(this.reservation.client.countryId);
          const country = ApplicationData.Countries.find(x => x.id == this.reservation.client.countryId);
          if (typeof country != 'undefined') this.addReservationForm.controls.countryName.setValue(country.name);
        }


        this.addReservationForm.controls.vehicleRegistrationNumber.setValue(this.reservation.client.vehicleRegistrationNumber);
        this.addReservationForm.controls.address.setValue(this.reservation.client.address);
        this.addReservationForm.controls.comments.setValue(this.reservation.client.comments);
        this.addReservationForm.controls.clientType.setValue(this.reservation.client.clientType);
      }

      if (Utils.hasValue(this.reservation.color)) {
        this.addReservationForm.controls.ownColor.setValue(true);
      }

      // Details
      this.addReservationForm.controls.registration.setValue(this.reservation.registration);
      this.addReservationForm.controls.sourceId.setValue(this.reservation.sourceId);


      this.addReservationForm.controls.doorCode.setValue(this.reservation.doorCode);
      this.addReservationForm.controls.additionalInfo.setValue(this.reservation.additionalInfo);


      // Finances
      this.addReservationForm.controls.currency.setValue(this.reservation.currency);
      this.default_currency = this.reservation.currency;

      this.loadPrices(this.reservation);
      this.loadAdditionalServices();

      const days = (new Date(this.reservation.departure).getTime() - new Date(this.reservation.arrival).getTime()) / this.dayInMilliseconds;
      this.addReservationForm.controls.days.setValue(days, {emitEvent:false});

      // Payments
      this.addReservationForm.controls.prepayment.setValue(this.reservation.prepayment)

      if (Utils.isDateSet(this.reservation.prepaymentDeadline)) {
        this.addReservationForm.controls.prepaymentDeadline.setValue(DateTime.fromJSDate(new Date(this.reservation.prepaymentDeadline)));
      }
      else {
        this.addReservationForm.controls.prepaymentDeadline.setValue(null);
      }

      this.addReservationForm.controls.paymentType.setValue(this.reservation.paymentType);
      this.addReservationForm.controls.currency.setValue(this.reservation.currency);
      this.addReservationForm.controls.color.setValue(this.reservation.color);
      this.addReservationForm.controls.colorPckr.setValue(this.reservation.color);

      this.addReservationForm.controls.meal.setValue(this.reservation.meal);
      this.addReservationForm.controls.childrenPortion.setValue(this.reservation.childrenPortion);
      this.addReservationForm.controls.adultsPortion.setValue(this.reservation.adultsPortion);

      // Dynamic type
      if (
        this.reservation.paymentStatus == 0 && Utils.isDateSet(this.reservation.prepaymentDeadline)
      ) {
        this.addReservationForm.controls.paymentStatus.setValue("waitingForPrepayment");
      }
      else {
        this.addReservationForm.controls.paymentStatus.setValue(this.reservation.paymentStatus);
      }


      this._changeDetectorRef.markForCheck()
    }
  }

  // Prepare form for creating reservation
  prepareFormForCreatingReservation(data) {
    if (typeof data == 'undefined') return


    // If type is passed set selected type
    if (Utils.isDefined(data, "type")) {

      // Additional check
      if (data.type == 0 && Array.isArray(data.roomIdOrIds)) {
        throw new Error('Can not set type SINGLE with multiple roomdIds.')
      }
      this.addReservationForm.controls.type.setValue(data.type)
      this.addReservationForm.controls.type.updateValueAndValidity()
    }
    // If type is not passed try to set correct
    else {
      this.setReservationType(data?.roomIdOrIds) || 0;
    }

    if (typeof data.roomIdOrIds != 'undefined' && data.roomIdOrIds != null) {
      this.addReservationForm.controls.roomId.setValue(data.roomIdOrIds);
    }

    if (typeof data.forename !== 'undefined') {
      this.addReservationForm.controls.forename.setValue(data.forename)
    }
    if (typeof data.name !== 'undefined') {
      this.addReservationForm.controls.name.setValue(data.name)
    }
    if (typeof data.email !== 'undefined') {
      this.addReservationForm.controls.email.setValue(data.email)
    }
    if (typeof data.phone !== 'undefined') {
      this.addReservationForm.controls.phone.setValue(data.phone)
    }
    if (typeof data.adults !== 'undefined') {
      this.addReservationForm.controls.adults.setValue(data.adults)
    }
    if (typeof data.children !== 'undefined') {
      this.addReservationForm.controls.children.setValue(data.children)
    }




    // Check date format
    // Convert from date to luxon, requires instance of Date
    if (!(data.startDate instanceof Date)) {
      console.error("Invalid startDate type. Must be 'Date'.")
    }
    if (!(data.endDate instanceof Date)) {
      console.error("Invalid startDate type. Must be 'Date'.")
    }

    // Using moment for single check
    // Check if date are sam
    const startOfDayDate1 = moment(data.startDate).startOf('day');
    const startOfDayDate2 = moment(data.endDate).startOf('day');

    // Compare the two dates without considering the time
    if (startOfDayDate1.isSame(startOfDayDate2)) data.endDate = moment(data.endDate).add(1, 'days').startOf('day').toDate()

    // Set luxon dates
    this.addReservationForm.controls.arrival.setValue(DateTime.fromJSDate(data.startDate));
    this.addReservationForm.controls.departure.setValue(DateTime.fromJSDate(data.endDate));

    // Count reservation days
    const days = (data.endDate.getTime() - data.startDate.getTime()) / this.dayInMilliseconds;
    this.addReservationForm.controls.days.setValue(days);

    if(!!this.userSettings.settings.reservationAutomaticPrepaymentDaysTurnOn == true) {
      this.addReservationForm.controls.paymentStatus.setValue('waitingForPrepayment')
      const days = this.userSettings.settings.reservationAutomaticPrepaymentDays
      this.addReservationForm.controls.prepaymentDeadline.setValue(DateTime.now().plus({ days: days}))
    }

  }

  getInvoiceType(id) {
    let name = ApplicationData.InvoiceTypes.find(x => x.id == id)?.name || 'nie_znaleziono'
    return this.translate.translate(name)
  }

  async getInvoices() {

    if (!Utils.isDefined(this.reservation, "reservationId")) return;

    // TODO
    // const invoices = await this.invoicesService.getInvoices([this.reservation.reservationId])
    // if(typeof invoices == 'undefined') { this.invoices = []; return; } 
    // this.invoices = Object.values(invoices)

  }

  setReservationType(idOrIds): number {

    if (typeof idOrIds == 'undefined' || idOrIds == null) return 0;
    if (Array.isArray(idOrIds)) {
      if (idOrIds.length > 1) {
        this.addReservationForm.controls.type.setValue(1);
        return 1;
      }
      else {
        this.addReservationForm.controls.type.setValue(0);
        return 0;
      }

    }

    if (typeof this.reservation == 'undefined') {
      this.addReservationForm.controls.type.setValue(0);
      return 0;
    }
    else {
      if (Utils.isLock(this.reservation)) {
        this.addReservationForm.controls.type.setValue(2);
        return 2;
      }
      else {
        this.addReservationForm.controls.type.setValue(0);
        return 0;
      }
    }
  }

  // TODO
  // async getLatestChange() {
  //   this.latestChange = await this.dataService.db.history.where('object').equals('Reservations').last();
  //   if (typeof this.latestChange == 'undefined') return;

  //   if (!Utils.isNullOrEmpty(this.latestChange.employeeId)) {
  //     this.latestChange.employee = this.dataService.employees[this.latestChange.employeeId]
  //   }
  //   else {
  //     this.latestChange.user = {
  //       forename: this.userSettings.userData.forename,
  //       name: this.userSettings.userData.name
  //     }
  //   }

  //   if (this.reservation.userId == parseInt(this.userSettings.userData.userId)) {
  //     this.reservation.user = <DbEmployee>{
  //       forename: this.userSettings.userData.forename,
  //       name: this.userSettings.userData.name
  //     }
  //   }
  //   else {
  //     this.reservation.user = this.dataService.employees[this.reservation.userId]
  //   }
  // 

  updateSelectedRooms() {
    this.selectedRooms = [];
    if (typeof this.addReservationForm.controls.roomId.value == 'undefined') { return; }
    if (this.addReservationForm.controls.roomId.value == null) { return; }

    // Do not update if type is single reservation or blockade
    // Keep here blockade too, otherwise room is not selectable. (single selection)
    if (this.addReservationForm.controls.type.value != 0 && this.addReservationForm.controls.type.value != 2) {
      if (!Array.isArray(this.addReservationForm.controls.roomId.value)) {
        this.addReservationForm.controls.roomId.setValue([this.addReservationForm.controls.roomId.value], { emitEvent: false })
      }
    }

    
    if (this.addReservationForm.controls.type.value == 0) {
      const room = this._roomsService.getById(this.addReservationForm.controls.roomId.value);
      if (typeof room != 'undefined') this.selectedRooms.push(room)
    }
    else {
      if (Array.isArray(this.addReservationForm.controls.roomId.value)) {
        this.addReservationForm.controls.roomId.value.forEach(element => {
          const room = this._roomsService.getById(element);
          if (typeof room != 'undefined') this.selectedRooms.push(room);
        });
      }
    }


    this.selectedRooms.sort((a, b) => a.order - b.order);

    this._changeDetectorRef.markForCheck()
  }

  removeRoom(index) {
    if (!Array.isArray(this.addReservationForm.controls.roomId.value)) return;
    const rooms = this.addReservationForm.controls.roomId.value || [];
    rooms.splice(index, 1);
    this.addReservationForm.controls.roomId.setValue(rooms);

  }

  buildPaymentStatuses() {
    const settings = this.settingsService.userSettings.value.settings;

    const noPaymentColor = settings.calendarColorsTurnOn
    const waitingForAdvancePaymentColor = settings.calendarColorsWaitingForPrepayment
    const advancePaymentPaidColor = settings.calendarColorsWaitingForPrepayment
    const paidAllColor = settings.calendarColorsPaidAll

    return [
      { id: 0, color: noPaymentColor, name: this.translate.translate('brak_wplaty') },
      { id: "waitingForPrepayment", color: waitingForAdvancePaymentColor, name: this.translate.translate('oczekiwanie_na_zaliczke') },
      { id: 1, color: advancePaymentPaidColor, name: this.translate.translate('wplacona_zaliczka') },
      { id: 2, color: paidAllColor, name: this.translate.translate('wplacona_calosc') }
    ]
  }

  openSelectRoomDialogComponent() {
    this.dialogService.openSelectRoomDialogComponent({
      selectedIds: this.addReservationForm.controls.roomId.value,
      title: this.translate.translate('zaznacz_pomieszczenia'),
      confirmText: this.translate.translate('wybierz'),
      range: { start: new Date(this.addReservationForm.controls.arrival.value), end: new Date(this.addReservationForm.controls.departure.value) }
    }).subscribe(
      (data) => {
        if (data.status == true) {
          this.addReservationForm.controls.roomId.setValue(data.results)
        }
      }
    )
  }

  toggleDiscount() {
    this.show.discount = !this.show.discount;

    this.pricingSummary.setDiscount(
      this.show.discount,
      this.addReservationForm.controls.discount.value,
      this.addReservationForm.controls.discountType.value,
    )
    this.addReservationForm.controls.discount.setValue(this.pricingSummary.discount)
  }

  updateAdditionalServicePrice(event, obj:IAdditionalService) {
    obj.price = event.target.value;
    this.pricingSummary.setAdditionalServices(this.reservationAdditionalServices);
    this._calculatePrepaymentAmount()
  }

  updateAdditionalServiceStatus(event, obj:IAdditionalService) {
    obj.status = event.value;
    this.pricingSummary.setAdditionalServices(this.reservationAdditionalServices);
  }

  loadPrices(reservation: DbReservation) {

    if (Utils.isDefined(reservation, 'pricePerRoomPerNight')) {
      let value = reservation.pricePerRoomPerNight
      if(Number.isNaN(value)) value = 0
      this.addReservationForm.controls.pricePerRoomPerNight.setValue(value);
    }

    if (Utils.isDefined(reservation, 'pricePerRoom')) {
      let value = reservation.pricePerRoom
      if(Number.isNaN(value)) value = 0

      this.addReservationForm.controls.pricePerRoom.setValue(value);
      this.pricingSummary.setPricePerRoom(value)
    }

    if (Utils.isDefined(reservation, 'pricePerMeal')) {
      let value = reservation.pricePerMeal
      if(Number.isNaN(value)) value = 0
      this.addReservationForm.controls.pricePerMeal.setValue(value);
      this.pricingSummary.setFoodPrice(value)
    }

    if (Utils.isDefined(reservation, 'discount')) {
      let value = reservation.discount
      if(Number.isNaN(value)) value = 0
      this.addReservationForm.controls.discount.setValue(value);

      if(value > 0) {
        this.show.discount = true;
      }
    }

    if (Utils.isDefined(reservation, 'discountType')) {
      this.addReservationForm.controls.discountType.setValue(reservation.discountType);
    }

    if (Utils.isDefined(reservation, 'discount') && Utils.isDefined(reservation, 'discountType')) {
      let discount = reservation.discount
      if(Number.isNaN(discount)) discount = 0
      this.pricingSummary.setDiscount(this.show.discount, discount, reservation.discountType)
    }

    if (Utils.isDefined(reservation, 'kidsAge')) {
      console.log(reservation.kidsAge)
      reservation.kidsAge.forEach((kidAge, index) => {
        const control = this.addReservationForm.controls['kidAge' + (index + 1)];
        if (typeof control != 'undefined') control.setValue(kidAge);
      })
    }

  }

  updateKidsFormControls(value) {
    Object.keys(this.addReservationForm.controls).forEach(key => {

      if (key.startsWith('kidAge')) {
        let number = key.split('kidAge');
        if (typeof number[1] != 'undefined' && number[1] > value) {
          this.addReservationForm.removeControl(key);
        }
      }
    })

    if (value == 0) { return; }

    for (let i = 1; i <= value; i++) {
      this.addReservationForm.addControl('kidAge' + i, new FormControl(1, Validators.required));
    }
    this.addReservationForm.updateValueAndValidity();
  }

  // Set names for additional services
  loadAdditionalServices() {
    this.reservationAdditionalServices =  this.pricingSummary.additionalServicesList
  }

  displayFormErrors(formGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];
      if (control instanceof FormGroup) {
        this.displayFormErrors(control); // rekurencyjnie sprawdza formularze w formularzach
      } else {
        control.markAsTouched(); // oznacza kontrolkę jako dotkniętą (touched) aby wyświetlić błędy
        control.markAsDirty(); // oznacza kontrolkę jako zanieczyszczoną (dirty) aby wyświetlić błędy
      }
    });
    formGroup.updateValueAndValidity(); // aktualizuje wartość i ważność formularza
  }

  filteredCountries = [];
  browseCountries(input: string) {
    this.filteredCountries = this.countries.map((c) => {
      return {
        id: c.id,
        name: this.translate.translate('country_' + c.id)
      }
    }).filter(x => x.name.toLowerCase().includes(input.toLowerCase()));
  }

  setLanguage(language) {
    if(language) this.addReservationForm.controls.language.setValue(language.shortName)
  }

  setCountry(country) {

    if (country == null || typeof country == 'undefined') {
      this.addReservationForm.controls.countryId.setValue(null)
      this.addReservationForm.controls.countryName.setValue('')
      return
    }
    this.addReservationForm.controls.countryId.setValue(country.id)
    this.addReservationForm.controls.countryName.setValue(this.translate.translate('country_' + country.id))
  }

  cancelReservation() {

    if (!this.permissions.has('deleteReservation')) {
      this._snackbarService.showSnackBar(this.translate.translate('brak_uprawnien'), 'success')
      return
    }
    this.dialogService.confirmDeletingReservation(this.reservation)
      .subscribe(
        {
          next: (data) => {
            if (data == true) {
              this._snackbarService.showSnackBar(this.translate.translate('anulowano_rezerwacje'), 'success')
              this._synchronizationService.synchronizeWithPendingCheck('Canceling reservation')
              this.close();
            }
          },
          error: (err: HttpErrorResponse) => {
            // this.authService.handleError(err);
            this.processing = false;
          }
        }

      );
  }

  updateColor(ev) {
    this.addReservationForm.controls.color.setValue(ev)
    this.addReservationForm.controls.colorPckr.setValue(ev)
  }

  close() {
    this.panelService.close();
    this.panelService.clearPanelPortal();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnInit(): void { //private refreshService: RefreshServiceService,
    // this.loadReservationForm(this.data.arrival, this.data.departure);
    this.addReservationForm.get('name').valueChanges
      .pipe(debounceTime(200))
      .subscribe(response => {
        this.searchClient(response);
      });
  }

  /*
  * Add reservation 
  */

  controlTabs = {
    name: 'klient',
    roomId: 'rezerwacja'
  }


  errors = {
    rezerwacja: false,
    klient: false,
    szczegoly: false,
    finanse: false
  }


  processFormErrors(controls) {
    this.errors = {
      rezerwacja: this.errors.rezerwacja,
      klient: this.errors.klient,
      szczegoly: this.errors.szczegoly,
      finanse: this.errors.finanse
    }

    if (Array.isArray(controls) && controls.length > 0) {
      controls.forEach(element => {
        if (element == 'rooms' && this.addReservationForm.controls.type.value != 1) return;
        if (element == 'room' && this.addReservationForm.controls.type.value == 1) return;

        if (typeof this.controlTabs[element] != 'undefined') {
          this.errors[this.controlTabs[element]] = true;
          // switch (this.controlTabs[element]) {
          //   case 'rezerwacja': this.errors.rezerwacja = true; break;
          //   case 'klient': this.errors.klient = true; break;
          //   case 'szczegoly': this.errors.szczegoly = true; break;
          //   case 'finanse': this.errors.finanse = true; break;
          // }
        }
      })
    }
  }


  // Create or update reservation
  createOrUpdateReservation() {


    // this.processFormErrors(Utils.getFormValidationErrors(this.addReservationForm));
    Object.keys(this.controlTabs).forEach((key) => {
      this.processFormErrors(Utils.getFormControlValidationErrors(this.addReservationForm, key, false))
    })

    if (this.addReservationForm.invalid) {
      this.addReservationForm.markAllAsTouched();
      this._snackbarService.showSnackBar(this.translate.translate('popraw_bledy_formularza'), 'error');
      return;
    }

    this.processing = true;

    // Avoid reference to form
    // If it has reference, i cant update that variable
    // By clonedeep
    let formData = cloneDeep(this.addReservationForm.value);

    delete formData.countryName;
    // Konwersja prepayment (oczekiwanie na zaliczke)
    if (formData.paymentStatus == 'waitingForPrepayment') { formData.paymentStatus = 0; }

    // Wlasny color
    if (!formData.ownColor) {
      formData.color = '';
      formData.colorPckr = '';
    }

    formData.ownColor = Utils.toStringNumberFromBoolean(formData.ownColor);

    formData.arrival = Utils.formatDate(new Date(formData.arrival.ts))
    formData.departure = Utils.formatDate(new Date(formData.departure.ts))

    if (Utils.isDefined(formData, "prepaymentDeadline.ts")) {
      formData.prepaymentDeadline = Utils.formatDate(new Date(formData.prepaymentDeadline.ts))
      if (formData.prepaymentDeadline == null) {
        formData.prepaymentDeadline = ''
      }
    }


    formData.kidsAge = Object.keys(this.addReservationForm.controls).filter(x => x.startsWith('kidAge')).map(x => this.addReservationForm.controls[x].value);
    formData.additionalServices = this.reservationAdditionalServices.map(x => {
      return {
        additionalServiceId: x.additionalServiceId,
        price: x.price,
        status:  x.status
      }
    });

    let cena_za_dodatki = 0;
    this.reservationAdditionalServices.forEach(service => {
      if (!Number.isNaN(service.price)) cena_za_dodatki += service.price
    })

    if(this.show.discount == false) {
      formData.discount = 0
      delete formData.discountType
    }

    if (formData.type == 0) formData.type = 'SINGLE'
    else if (formData.type == 1) formData.type = 'GROUP'
    else if (formData.type == 2) formData.type = 'LOCK'

    // formData.cena_za_dodatki = cena_za_dodatki?.toFixed(2) || 0
    // formData.suma_za_dobe = (this.pricingSummary.summary / this.addReservationForm.controls.days.value).toFixed(2)
    // formData.suma = this.pricingSummary.summary.toFixed(2) || 0

    // Client fields
    const clientFields = [
      'clientId',
      'name',
      'forename',
      'phone',
      'email',
      'idCard',
      'taxId',
      'companyName',
      'personalId',
      'vehicleRegistrationNumber',
      'address',
      'comments',
      'countryId',
      'countryName',
      'clientType',
      'language',
    ]

    if(formData.type != 'LOCK') {
      formData.client = {}
      clientFields.forEach(field => {
        formData.client[field] = this.addReservationForm.controls[field].value;
        delete formData[field];
      });
    }
 
    if (this.mode == 'create') {

      if (!this.permissions.has('addReservation')) {
        this._snackbarService.showSnackBar(this.translate.translate('brak_uprawnien'), 'info', 3000)
        return
      }

      this.apiService.addReservation(formData)
        .pipe(take(1))
        .pipe(finalize(()=> {
          this.processing = false;
        }))
        .subscribe(
          {
            next: (response) => {
              if (response.status == 'success') {

                if(Utils.isDefined(response,"data.reservationId")) {
                  this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
                }
                const successResponseTranslation = formData.type != 'LOCK' ? 'dodano_rezerwacje' : 'dodano_blokade';
                this._snackbarService.showSnackBar(this.translate.translate(successResponseTranslation), 'success');
                this._synchronizationService.synchronizeWithPendingCheck("Added reservation")
                this.panelService.close();
                //tutaj trzeba zrobić if !empty(response.errors) then foreach wyświetl błędy. Trzeba przerobić _snackbarServicea, żeby mógł wyświetlać kilka błędów na raz, albo po timeoucie
                //Mogło dodać prawidłowo rezerwację, ale np. nie wysłało dostępności do bookingu, w celu zablokowania terminu
              }
              else { // tutaj są tylko będy walidacji
                this._snackbarService.showSnackBar(this.translate.translate('wystapil_blad'), 'error');
                //Trzeba będzie zrobić jakąś listę błędów np. OCCUPIED_TERM - onacza, że termin jest zajęty i błąd należy wyświetlić w żółtym kolorze, bo to nie błąd tylko warning.
              }
              this._changeDetectorRef.markForCheck()
            },
            error: (err: HttpErrorResponse) => {
              // this.authService.handleError(err);
              this._changeDetectorRef.markForCheck()
            }
          })
    }
    else if (this.mode == 'update') {

      if (!this.permissions.has('editReservation')) {
        this._snackbarService.showSnackBar(this.translate.translate('brak_uprawnien'), 'info', 3000)
        return
      }

      this.apiService.updateReservation(this.reservation?.reservationId, formData)
        .pipe(take(1))
        .subscribe(
          {
            next: async (response) => {
              if (response.status == 'success') {

                // Only if reservations updated
                if(Utils.isDefined(response,"data.reservationId")) {
                  this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
                }

                this.handlingService.handleResponse(response, 'edytowano_rezerwacje');
                // this._snackbarService.showSnackBar(this.translate.translate('dodano_rezerwacje'), 'success');
                this._synchronizationService.synchronizeWithPendingCheck("Editing of reservation")
                this.panelService.close();
                //tutaj trzeba zrobić if !empty(response.errors) then foreach wyświetl błędy. Trzeba przerobić _snackbarServicea, żeby mógł wyświetlać kilka błędów na raz, albo po timeoucie
                //Mogło dodać prawidłowo rezerwację, ale np. nie wysłało dostępności do bookingu, w celu zablokowania terminu
                //będziemy musieli zrobić jakiś alercik, żeby jeszcze raz wysłać dostępność do bookingu.
                //Jeżeli wszystko OK to zamykaj sidebara i rób synchronizację + odśwież grafik...
              }
              else { // tutaj są tylko będy walidacji
                this._snackbarService.showSnackBar(this.translate.translate('wystapil_blad'), 'error');
                //Trzeba będzie zrobić jakąś listę błędów np. OCCUPIED_TERM - onacza, że termin jest zajęty i błąd należy wyświetlić w żółtym kolorze, bo to nie błąd tylko warning.
                this.processing = false;
              }
              this._changeDetectorRef.markForCheck()

            },
            error: (err: HttpErrorResponse) => {
              // this.authService.handleError(err);
              this.processing = false;
              this._changeDetectorRef.markForCheck()
            }
          })
    }

  }


  removeAdditionalService(indexOf) {

    if(Utils.getNestedValue(this.res,"status") == 0 && this.mode == 'update') return

    this.reservationAdditionalServices.splice(indexOf, 1);
    this.pricingSummary.setAdditionalServices(this.reservationAdditionalServices);
    this._calculatePrepaymentAmount()
  }

  openAdditionalServiceDialog(): void {
 
    if(Utils.getNestedValue(this.res,"status") == 0 && this.mode == 'update') return
    this.subscriptions.push(
      this.dialogService.showSelectAdditionalServicesDialog(
      this.reservationAdditionalServices.map(x => x.additionalServiceId)
    )
      .subscribe({
        next: (data) => {
          if (data == null) return
          if (isBoolean(data)) return
          if (data.status == false) return
          if (data.results == null) return

          // Contains list of { service, status }
          // Service = id_dodatku, Status: checked or not
          data.results.forEach(additionalServiceId => {

            //If exists it 
            const elem = this.reservationAdditionalServices.find(x => x.additionalServiceId == additionalServiceId);

            // Check if add is possible
            if (typeof elem == 'undefined') {
              
              const service = this._additionalServicesService.getById(additionalServiceId);
              if (!service != null) return

              this.reservationAdditionalServices.push({
                additionalServiceId: service.additionalServiceId,
                displayName: service.displayName,
                price: 0,
                status: 0,
                taxRate: service.taxRate
              })
              
            }

          })

          this.reservationAdditionalServices = this.reservationAdditionalServices.filter((service: IAdditionalService) => {
            // Check if the service.additionalServiceId is not in the data.results array
            return data.results.includes(service.additionalServiceId);
          });

          this.pricingSummary.setAdditionalServices(this.reservationAdditionalServices)
          this._changeDetectorRef.markForCheck()
        }
      }
      ))
  }

  showReservationBookingDataDialog() {
    this.dialog.open(ShowReservationBookingDataDialogComponent, {
      panelClass: 'mobile-calendar-responsive-dialog',
      data: {
          reservation: this.reservation
      }
    })
  }

  showHistoryDialog() {
    this.dialog.open(ReservationHistoryComponent, {
      maxWidth: '100%',
      data: {
        reservation: this.reservation
      }
    })
  }

  showUrlPaymentDialog() {
    this.dialog.open(PaymentLinkComponent, {
      maxWidth: '100%',
      data: {
        reservation: this.reservation
      },
      // width: "600px"
    })
  }

  showBookingData() {
    if (Utils.isDefined(this.reservation, "booking_details")) {
      this.dialog.open(ShowReservationBookingDataComponent, {
        data: {
          reservation: this.reservation
        }
      })
    }
  }

  searchClients: DbClient[] = [];
  searchClient(search) {
    const paginatedResult = this._clientsService.browseClients({
      filters: [
        (client:DbClient) => clientFilter({client: client, value: search.toLowerCase()})
      ]
    })
    

    this.searchClients = paginatedResult.allResults.slice(0, Math.min(30, paginatedResult.resultsCount));
  }

  clearClient() {

    if(Utils.getNestedValue(this.res,"status") == 0 && this.mode == 'update') return

    this.addReservationForm.controls['clientId'].setValue(null);
    this.addReservationForm.controls['forename'].setValue('');
    this.addReservationForm.controls['name'].setValue('');
    this.addReservationForm.controls['phone'].setValue('');
    this.addReservationForm.controls['email'].setValue('');
    this.addReservationForm.controls['idCard'].setValue('');
    this.addReservationForm.controls['personalId'].setValue('');
    this.addReservationForm.controls['taxId'].setValue('');
    this.addReservationForm.controls['companyName'].setValue('');
    this.addReservationForm.controls['clientType'].setValue(0);
    this.addReservationForm.controls['comments'].setValue('');
    this.addReservationForm.controls['address'].setValue('');
    this.addReservationForm.controls.vehicleRegistrationNumber.setValue('');
    this.addReservationForm.controls.countryId.setValue('');

    this.addReservationForm.controls.countryName.setValue('');
    this.addReservationForm.controls.countryName.updateValueAndValidity();
  }

  saveClientData(data:DbClient) {
    this.addReservationForm.controls['clientId'].setValue(data.clientId);
    this.addReservationForm.controls['forename'].setValue(data.forename);
    this.addReservationForm.controls['phone'].setValue(data.phone);
    this.addReservationForm.controls['email'].setValue(data.email);
    this.addReservationForm.controls['idCard'].setValue(data.idCard);
    this.addReservationForm.controls['personalId'].setValue(data.personalId);
    this.addReservationForm.controls['taxId'].setValue(data.taxId);
    this.addReservationForm.controls['companyName'].setValue(data.companyName);
    this.addReservationForm.controls['clientType'].setValue(data.clientType);
    this.addReservationForm.controls['comments'].setValue(data.comments);
    this.addReservationForm.controls['address'].setValue(data.address);
    this.addReservationForm.controls['language'].setValue(data.language);
    this.addReservationForm.controls.vehicleRegistrationNumber.setValue(data.vehicleRegistrationNumber)
    if (Utils.isDefined(data, "kraj")) {

      this.addReservationForm.controls.countryId.setValue(data.countryId);
      this.addReservationForm.controls.countryId.updateValueAndValidity();
      const country = ApplicationData.Countries.find(x => x.id == data.countryId);

      if (typeof country != 'undefined') {
        this.addReservationForm.controls.countryName.setValue(this.translate.translate('country' + country.id));
        this.addReservationForm.controls.countryName.updateValueAndValidity();
      }
    }
  }

  selectPaymentStatus(status) {
    if (typeof this.reservation != 'undefined') { if (this.reservation.status == 0) { return } }
    this.addReservationForm.controls.paymentStatus.setValue(status)
  }

  decrement(inputName, min) {
    let curr_val = parseInt(this.addReservationForm.get(inputName).value);
    if (curr_val == min)
      return;
    this.addReservationForm.controls[inputName].setValue(curr_val - 1);
  }

  increment(inputName, min) {
    let curr_val = parseInt(this.addReservationForm.get(inputName).value);
    this.addReservationForm.controls[inputName].setValue(curr_val + 1);
  }

  reservationForm = {
    rooms: [],
    currencies: [],
    additionalServices: []
  };

  dialogRefShowOnForm;
  showOnFormSettings(): void {
    this.dialogRefShowOnForm = this.dialog.open(ShowOnFormComponent, {
      // maxWidth: '100%',
      // width: '100%',
    });
  }

  switchToReservation() {
    this.addReservationForm.controls.type.setValue(0)
  }

  dialogRefCopyReservation;
  showCopyReservation(): void {

    let disabledRooms = []

    if (Array.isArray(this.addReservationForm.controls.roomId.value)) disabledRooms = [...this.addReservationForm.controls.roomId.value]
    else disabledRooms.push(this.addReservationForm.controls.roomId.value)

    this.dialogService.openSelectRoomDialogComponent({
      selectedIds: [],
      disabled: disabledRooms,
      title: this.translate.translate('kopiuj'),
      confirmText: this.translate.translate('kopiuj'),
      range: { start: this.addReservationForm.controls.arrival.value, end: this.addReservationForm.controls.departure.value }
    }).subscribe(
      (data) => {
        if (data.status == true) {
          // this.addReservationForm.controls.rooms.setValue(data.results)
          this.apiService.copyReservation(this.reservation.reservationId, data.results)
          .pipe(take(1))
          .subscribe({
              next: (response) => {
                if (response.status == 'success') {
                  this._snackbarService.showSnackBar(this.translate.translate('skopiowano_rezerwacje'), "success")
                  this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
                  this._synchronizationService.synchronizeWithPendingCheck("Copying reservations");
                }
                else {
                  if (response.status == "error") {
                    response.errors.forEach(element => {
                      this._snackbarService.showSnackBar(this.translate.translate(element), "error")
                    });
                    this.showCopyReservation()
                  }
                }
              }
            }
          )
        }
      }
    )

  }

  dialogRefSendConfirmtion;
  showSendConfirmation(): void {
    this.dialogRefSendConfirmtion = this.dialog.open(ReservationConfirmationDialogComponent, {
      data: {
        reservation: this.reservation
      },
      panelClass: 'mobile-calendar-responsive-dialog'
    });
  }

  dialogRefSourceManage;
  showSourceManage(): void {

    if(Utils.getNestedValue(this.res,"status") == 0 && this.mode == 'update') return
    this.dialogRefSourceManage = this.dialog.open(SourceManageComponent, {
      panelClass: 'mobile-calendar-responsive-dialog'
    });
  }

  tabIndex = 0;

  prevTab() {
    if (this.tabIndex > 0)
      this.tabIndex--;
  }

  nextTab() {
    this.tabIndex++;
  }

  changeTab($event) {
    this.tabIndex = $event;
  }

  showRestoreReservationConfirmation() {
    
    if (!this.permissions.has('restoreReservation')) {
      this._snackbarService.showSnackBar(this.translate.translate('brak_uprawnien'), 'info', 3000)
      return
    }

    this.dialogService.showRestoreReservationConfirmation(this.reservation).subscribe({
      next: (data) => {
        if(data != true) return
        this._synchronizationService.newSynchronize('Restoring reservation')
        this.close()
      }
    })
  }

  navigateToReservation() {
    if (typeof this.reservation == 'undefined') return;
    this.calendarNavigationService.navigateToReservation(this.reservation.roomId, this.reservation.reservationId)
  }

  availableRooms: any = [];
  excluded = []
  browseAvailableRooms() {
    
    if(!Utils.isDefined(this.addReservationForm.controls.arrival, "value") || !Utils.isDefined(this.addReservationForm.controls.departure, "value")) {
      return
    }

    const availability = this.availabilityService.getAvailability(
      this.addReservationForm.controls.arrival.value,
      this.addReservationForm.controls.departure.value,
      null,
      null,
      this.excluded
    )

    this.availableRooms = availability.availableRooms

    // If its a already created reservation exclude current room Id
    if (this.mode == 'update') {
      const index = this.availableRooms.findIndex(x => x.roomId == this.reservation.roomId)
      const room = this._roomsService.getById(this.reservation.roomId)
      if (index == -1 && room) {  this.availableRooms.unshift(room)}
    }

    this.addReservationForm.controls.roomId.updateValueAndValidity()
  }

  // Configuring prices for groups
  showConfigureGroupPricesDialog() {
    this.dialog.open(ConfigureGroupPricesComponent, {
      maxWidth: '100%'
    })
  }

  showCalculatePricesDialog() {
    const dialogRef = this.dialog.open(CalculatePricesDialogComponent, {
      data: {
        currency: this.addReservationForm.controls.currency.value,
        pricePerRoomPerNight: this.addReservationForm.controls.pricePerRoomPerNight.value,
        days: this.addReservationForm.controls.days.value
      },
      panelClass: 'mobile-calendar-responsive-dialog'
    })

    dialogRef.afterClosed().subscribe({
      next: (data) => {
        if(data == null || typeof data == 'undefined') return
        
        if(typeof data.pricePerRoom != 'undefined') {
          this.addReservationForm.controls.pricePerRoom.setValue(data.pricePerRoom)
        }

        this.priceChangesDetector = false
        this._changeDetectorRef.markForCheck()
      }
    })
  }

  resetPrepaymentDeadling() {
    this.addReservationForm.controls.prepaymentDeadline.setValue(null)
  }

  // Automatically add color to date if is available
  dateClass: MatCalendarCellClassFunction<DateTime> = (cellDate, view) => {

    const reservationType = this.addReservationForm.controls.type.value

    // Only highligh dates inside the month view.
    // Only when is lock or single type
    // if (view === 'month' && (reservationType == 0 || reservationType == 2)) {

    //   let excludedIds = []
    //   if(this.mode == 'update') {
    //  if(this.res)
    //     excludedIds.push(this.res.reservationId)
    //   }

    //   // If end date is not selected
    //   // Check availability per
    //   let sDate = cellDate.minus({ days: 1})
    //   let eDate = cellDate
    //   const arrivalDate:DateTime = this.addReservationForm.controls.arrival.value

    //   if(cellDate.toJSDate().getTime() == arrivalDate.toJSDate().getTime()) {
    //     sDate = cellDate
    //     eDate = cellDate.plus({ days: 1})
    //   }

    //   const date = cellDate.day;
    //   const availability = this.availabilityService.getAvailability(
    //     sDate,
    //     eDate,
    //     null,
    //     null,
    //     excludedIds
    //   )

    //   const isAvailable = availability.availableRooms.map(x=>x.roomId).includes(this.addReservationForm.controls.roomId.value)
    //   // Highlight the 1st and 20th day of each month.
    //   return isAvailable ? 'date-available' : 'date-unavailable';
    // }

    return '';
  };
}