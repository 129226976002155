<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <!-- <span class="relative"> -->
        <user-avatar-image customClass="w-7 h-7 text-md rounded-full"></user-avatar-image>
<!--         
        <mat-icon
            *ngIf="!showAvatar || !user?.userData?.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon> -->
        <span class="absolute w-2 h-2 rounded-full" style="right: 7px !important;bottom: 5px !important;"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user?.userData?.avatar,
                          'bg-green-500': !ics.offlineMode.value,
                          'bg-red-500': ics.offlineMode.value}"
        ></span>
    <!-- </span> -->
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="font-weight-bolder">{{'zalogowano_jako' | transloco}}:</span>
            <span class="mt-1.5 text-md font-medium">{{user?.userData.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="'/app/my-account'| localize">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{'moje_konto' | transloco}}</span>
    </button>
    <button mat-menu-item [routerLink]="'/app/settings'| localize">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>{{'ustawienia' | transloco}}</span>
    </button>
    <button *ngIf="user.connectedAccounts.length > 1"
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
        <span>{{'przelacz_konto' | transloco}}</span>
    </button>
    <button
        mat-menu-item (click)="addNewAccount()">
        <mat-icon [svgIcon]="'heroicons_outline:plus-circle'"></mat-icon>
        <span>{{'dodaj_kolejne_konto' | transloco}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="lockScreen()">
        <mat-icon [svgIcon]="'heroicons_outline:lock-closed'"></mat-icon>
        <span>{{'ekran_blokady' | transloco}}</span>
    </button>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>{{'wyloguj_sie' | transloco}}</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <ng-container *ngFor="let acc of user.connectedAccounts">
        <button mat-menu-item *ngIf="acc.userId !== user.userData.userId" (click)="relogin(acc)">
            <span class="flex flex-col leading-none">
                <span class="font-weight-bolder">{{acc.email}}</span>
                <span class="mt-1.5 text-secondary text-sm font-medium">{{acc.hotelName}}</span>
            </span>
            <mat-icon [matTooltip]="translate.translate('tryb_offline_dostepny')" color="heroicons_solid:success"
            *ngIf="ics.offlineMode.value && acc.isOfflineModeAvailable">done</mat-icon>
          <mat-icon [matTooltip]="translate.translate('tryb_offline_niedostepny')" color="heroicons_solid:warn"
            *ngIf="ics.offlineMode.value && !acc.isOfflineModeAvailable">warning</mat-icon>
        </button>
    </ng-container>
   

</mat-menu>
