import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { api_v1, environment } from 'environments/environment';
import { Room } from '../models/Room';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private localize: LocalizeRouterService,
    private translate: TranslocoService
  ) {

  }

  transcribeAudio(file: Blob): Observable<any> {
    let apiUrl = api_v1 + 'aiassistant';
    const formData = new FormData();
    formData.append('file', file, 'audio.wav');

    return this.http.post(apiUrl, formData);
  }

  // Users
  // Change user default language
  deleteAccount(data) {
    return this.http.post(api_v1 + '/user/delete', data)
  }
  changeLanguage(lang) {
    return this.http.patch(api_v1 + '/user/change-language', { lang: lang })
  }

  // Meals
  getMeals() {
    return this.http.get(api_v1 + 'meals')
  }

  updateMeals(requestData) {
    return this.http.post(api_v1 + 'meals', requestData)
  }



  markNotificationsAsRead(id) {
    return this.http.patch(api_v1 + `notifications/${id}/readed`, {})
  }

  deleteNotifications(id) {
    return this.http.delete(api_v1 + `notifications/${id}`)
  }

  sendReservationConfirmation(formData: FormData) {
    return this.http.post(api_v1 + 'confirmation', formData)
  }



  // My account
  uploadUserAvatar(formData) {
    return this.http.post<any>(api_v1 + 'user/avatar', formData);
  }

  deleteUserAvatar() {
    return this.http.delete<any>(api_v1 + 'user/avatar');
  }

  linkAccount(data) {
    data.lang = this.translate.getActiveLang()
    return this.http.post(api_v1 + 'user/account/link', data)
  }

  linkAndCreateAccount(data) {
    data.lang = this.translate.getActiveLang()
    return this.http.post(api_v1 + 'user/account/link/new', data)
  }

  unlinkAccount(userId) {
    return this.http.delete(api_v1 + `user/account/link/${userId}`)
  }

  updateUserSettings(field, value) {
    let requestData = {}
    requestData[field] = value
    return this.http.patch(api_v1 + 'settings', requestData)
  }

  changeUserData(data) {
    return this.http.post(api_v1 + 'user/data', data)
  }

  changeHotelData(data) {
    return this.http.post(api_v1 + 'user/hotel-data', data)
  }

  getInvoices({page}) {
    return this.http.get(api_v1 + `user/documents?page=${page}`)
  }

  getTaxOffices() {
    return this.http.get(api_v1 + `tax-offices`)
  }  

  getCurrencyExchange(date, baseCurrency, targetCurrency) {
    return this.http.get(api_v1 + `exchange?date=${date}&base=${baseCurrency}&symbols=${targetCurrency}`)
  }

  downloadInvoice(documentId) {
    return this.http.get(api_v1 + `user/documents/${documentId}`,{ responseType: 'blob' })
  }

  // Booking.com channel manager
  initBookingChannelManager() {
    return this.http.get(api_v1 + 'booking/init')
  }

  bookingRooms() {
    return this.http.get(api_v1 + 'booking/rooms')
  }

  getChannelbyId(channelId) {
    return this.http.get(api_v1 + `init/channel-manager/${channelId}`)
  }
  
  getChannels() {
    return this.http.get(api_v1 + 'init/channel-manager')
  }

  getChannelManagerAvailability() {
    return this.http.post(api_v1 + 'channel-manager/availability',{})
  }

  retryRequest(url,data) {
    return this.http.post(url,data)
  }

  refreshICalendar(roomId) {
    return this.http.get(api_v1 + `icalendar/rooms/${roomId}`)
  }

  setIcalRoomUrl(channelId, roomId, url) {
    return this.http.post(api_v1 + 'icalendar',{
      sourceId: Number(channelId),
      roomId: roomId, 
      icalUrl:url
    })
  }

  deleteLinkFromChannelRoom(icalId) {
    return this.http.delete(api_v1 + `icalendar/${icalId}`)
  }

  importReservationsFromBooking() {
    return this.http.post(api_v1 + 'booking/import/reservations',{})
  }

  connectRoomsBookingChannelManager(roomIds) {
    return this.http.post(api_v1 + 'booking/map-rooms',{ roomId: roomIds})
  }

  setHotelIdBookingChannelManager(hotelIds) {
    return this.http.post(api_v1 + '/booking/hotel-id',{ hotelId: hotelIds})
  }

  toggleBoookingChannelManagerSynchronization(turnOn) {
    return this.http.post(api_v1 + 'booking/sync',{turnOn: turnOn})
  }

  // Pricing
  changePriceOrRestriction(
    {channelId, startDate, days, endDate, rooms, rateId, data}:{channelId:string, startDate: string, days:any, endDate: string, rooms, rateId: number, data:any}) {

    const request:any = {
      startDate: startDate,
      stopDate: endDate,
      roomId: rooms,
      rateId: rateId,
      data: data
    }

    if(days != null && days.length > 0) {
      request.days = days
    }
    
    return this.http.post(api_v1 + `pricing/${channelId}`, request)

  }

  updatePricingModel(pricingId:string, request) {
    return this.http.post(api_v1 + `pricing-model/${pricingId}`,request)
  }

  pricingCurrency(currency:string) {
    return this.http.post(api_v1 + `pricing-currency`, {
      currency: currency
    })
  }

  fetchChannels() {
    return this.http.get(api_v1 + `init/pricing`)
  }

  register(email: string, password: string, rules: boolean, lang: string) {
    return this.http.post(api_v1 + 'auth/register.php', {
      email: email,
      password: password,
      accept_regulations: rules,
      lang: lang
    });
  }

  login(email: string, password: string, deviceId: string, device: string, deviceName: string, platform: string) {
    return this.http.post(api_v1 + 'login', { email, password, deviceId, device, deviceName, platform });
  }

  checkPromoCode(promoCode: string) {
    return this.http.post(api_v1 + 'premium/promo_code.php', { code: promoCode });
  }

  verifyRemindPasswordToken(token: string) {
    return this.http.post(api_v1 + 'auth/password_token_verify.php', { token: token });
  }

  changePasswordByToken(password: string, token: string) {
    return this.http.post(api_v1 + 'auth/password_change_reminder.php', { password: password, token: token });
  }

  loginWithFacebook(accessToken) {
    return this.http.post(api_v1 + 'auth/thrid_party/facebook_login.php', {
      accessToken
    })
  }

  loginWithGoogle(idToken, lang, email, firstName, lastName, photoUrl, device_id: string, device: string, device_name: string, platform: string) {
    return this.http.post(api_v1 + 'auth/login_google.php', {
      idToken, lang: lang, email, firstName, lastName, photoUrl, device_id, device, device_name, platform
    })
  }

  loginWithApple(authorizationCode, identityToken) {
    return this.http.post(api_v1 + 'auth/thrid_party/apple_login.php', { authorizationCode: authorizationCode, identityToken: identityToken })
  }

  unregisterEmployeeDevice(employeeid: number, deviceId: string) {
    return this.http.delete<any>(api_v1 + `/employees/${employeeid}/devices/${deviceId}`);
  }

  unregisterEmployeeDevices(employeeid: number,) {
    return this.http.delete<any>(api_v1 + `/employees/${employeeid}/devices`);
  }

  logOut(): any {
    return this.http.post(api_v1 + 'auth/logout.php', {});
  }

  remindPassword(email, lang): any {
    return this.http.post(api_v1 + 'auth/password_reminder.php', {
      email: email,
      lang: lang
    });
  }

  changeEmailAddress(data: any) {
    return this.http.post(api_v1 + 'user/change-email', data);
  }

  changePassword(data: any) {
    return this.http.post(api_v1 + 'user/change-password', {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword
    });
  }

  relogin(userId: number, deviceId: string, device: string, deviceName: string, platform: string): any {
    return this.http.post(api_v1 + 'relogin', { userId, deviceId, device, deviceName, platform });
  }

  loginOneTimeToken(token: string) {
    return this.http.post(api_v1 + 'auth/login_one_time_token.php', { token });
  }

  refreshSettings() {
    return this.http.get(api_v1 + 'init');
  }

  importReservations(reservations): any {
    return this.http.post(api_v1 + 'reservations/import', { reservations });
  }

  getDevices(): Observable<any> {
    return this.http.get<any>(api_v1 + 'devices');
  }

  deleteDeviceById(id): Observable<any> {
    return this.http.delete<any>(api_v1 + `devices/${id}`);
  }

  getEmployeeDevices(employeeId): Observable<any> {
    return this.http.get<any>(api_v1 + `employees/${employeeId}/devices`);
  }

  getUserSettings(): Observable<any> {
    return this.http.get<any>(api_v1 + 'init');
  }

  saveSettings(data) {
    return this.http.post<any>(api_v1 + 'settings/save_settings.php', data);
  }

  restoreSettings() {
    return this.http.delete<any>(api_v1 + 'settings');
  }


  // Templates
  createTemplate(formData: FormData) {
    return this.http.post(api_v1 + 'templates', formData)
  }

  updateTemplate(templateId, formData: FormData) {
    return this.http.post(api_v1 + `templates/${templateId}`, formData)
  }

  deleteTemplate(templateId) {
    return this.http.delete<any>(api_v1 + 'templates/'+templateId);
  }

  // Reservations
  addReservation(data) {
    return this.http.post<any>(api_v1 + 'reservations', data);
  }

  updateReservation(reservationId, data) {
    return this.http.put<any>(api_v1 + 'reservations/' + reservationId, data);
  }

  registerWebPush(pushId) {
    return this.http.patch<any>(api_v1 + 'device/register-push', { pushId: pushId });
  }

  testPush() {
    return this.http.post<any>(api_v1 + 'push/push_test.php', {});
  }

  restoreRerservation(data) {
    return this.http.patch<any>(api_v1 + `reservations/${data.reservationId}/restore`, {
      group: data.group
    });
  }

  setReservationAsCheckedInOrCheckedOut(reservationId, value) {
    return this.http.patch<any>(api_v1 + `reservations/${reservationId}/registration`, {
      registration: value
    });
  }

  setReservationPaymentStatus(reservationId, value) {
    return this.http.patch<any>(api_v1 + `reservations/${reservationId}/payment-status`, {
      paymentStatus: value
    });
  }

  deleteReservation(reservationId: number, deleteGroup: boolean) {
    return this.http.delete<any>(api_v1 + `reservations/${reservationId}?deleteGroup=${deleteGroup}`);
  }

  moveReservation(id, data: any) {
    return this.http.put<any>(api_v1 + `reservations/${id}`, data);
  }

  copyReservation(reservationId, data: any) {
    return this.http.post<any>(api_v1 + `reservations/${reservationId}/copy`, {
      reservationId: reservationId,
      roomId: data
    });
  }

  /*
 
   Channel Manager

 */
  sendAvailability(data) {
    return this.http.post<any>(api_v1 + 'channel-manager/availability', data);
  }
  /*

  Clients

  */
  createClient(client) {
    return this.http.post<any>(api_v1 + 'clients', client);
  }
  updateClient(client) {
    return this.http.put<any>(api_v1 + `clients/${client.clientId}`, client);
  }
  deleteClient(clientId: number) {
    return this.http.delete<any>(api_v1 + `clients/${clientId}`);
  }

  /*
  
    Rooms

  */
  createRoom(data) {
    return this.http.post<any>(api_v1 + 'rooms', data);
  }

  restoreRoom(roomId) {
    return this.http.patch<any>(api_v1 + `rooms/${roomId}/restore`, null);
  }

  setRoomCleaningStatus(roomId, cleaningStatus) {
    return this.http.patch<any>(api_v1 + `rooms/${roomId}/service-status`, { service: cleaningStatus });
  }

  setRoomTaskDone(roomId) {
    return this.http.patch<any>(api_v1 + `rooms/${roomId}/service-info`,{ serviceInfo: ''});
  }

  setRoomServiceInfo(roomId, serviceInfo) {
    return this.http.patch<any>(api_v1 + `rooms/${roomId}/service-info`,{ serviceInfo: serviceInfo});
  }

  getRoomImages(roomId: number) {
    return this.http.get<any>(api_v1 + `rooms/${roomId}/photos`)
  }

  setRoomImages(roomId: number, formData) {
    return this.http.post<any>(api_v1 + `rooms/${roomId}/photos`, formData)
  }

  changeImageOrder(roomId: number, photoId: number, formData) {
    return this.http.patch<any>(api_v1 + `rooms/${roomId}/photos/${photoId}/order`, formData)
  }

  rotateRoomImage(roomId, photoId) {
    return this.http.patch<any>(api_v1 + `rooms/${roomId}/photos/${photoId}/rotate`, {})
  }

  deleteRoomImage(roomId: number, photoId: number) {
    return this.http.delete<any>(api_v1 + `rooms/${roomId}/photos/${photoId}`)
  }

  changeRoomOrder(data) {
    return this.http.patch<any>(api_v1 + 'rooms/change-order', data);
  }

  updateRoom(roomId, data) {
    return this.http.put<any>(api_v1 + 'rooms/' + roomId, data);
  }

  deleteRoom(roomId: number) {
    return this.http.delete<any>(api_v1 + 'rooms/' + roomId);
  }

  /*
  
    Employees

  */
  createEmployee(data: any) {
    return this.http.post<any>(api_v1 + 'employees', data);
  }

  updateEmployee(employeeId, data: any) {
    return this.http.put<any>(api_v1 + 'employees/' + employeeId, data);
  }

  updateEmployeePermissions(employeeId, data: any) {
    return this.http.put<any>(api_v1 + `employees/${employeeId}/permissions`, data);
  }
    
  restoreEmployeeSettings(employeeId) {
    return this.http.delete<any>(api_v1 + `employees/${employeeId}/settings`);
  }
/*
  
    Delete employee
    @params: employeeId

  */
  deleteEmployee(employeeId: number) {
    return this.http.delete<any>(api_v1 + `employees/${employeeId}`);
  }

  changeEmployeePassword(employeeId, data: any) {
    return this.http.put<any>(api_v1 + `employees/${employeeId}/change-password`, data);
  }
  changeEmployeeEmail(employeeId, data: any) {
    return this.http.put<any>(api_v1 + `employees/${employeeId}/change-email`, data);
  }
  logoutWorker(data: any) {
    return this.http.post<any>(api_v1 + 'employees/logout.php', data);
  }
  deleteWorkerAccount(data: any) {
    return this.http.post<any>(api_v1 + 'employees/delete_employee.php', data);
  }

  /*
  Backup endpoints
  */
  getBackup() {
    return this.http.get<any>(api_v1 + 'backup');
  }
  sendBackupNow() {
    return this.http.post<any>(api_v1 + 'backup/send', {});
  }
  saveBackup(data) {
    return this.http.post<any>(api_v1 + 'backup', data);
  }


  // Invoices
  createInvoice(data) {
    return this.http.post<any>(api_v1 + "invoices", data);
  }

  updateInvoice(invoiceId, data) {
    return this.http.put<any>(api_v1 + `invoices/${invoiceId}`, data);
  }

  deleteInvoice(invoiceId) {
    return this.http.delete<any>(api_v1 + `invoices/${invoiceId}`);
  }

  uploadInvoiceLogo(formData) {
    return this.http.post<any>(api_v1 + '/settings/invoices/logo', formData);
  }

  deleteInvoiceLogo() {
    return this.http.delete<any>(api_v1 + `settings/invoices/logo`);
  }

  searchInGUS(taxId) {
    return this.http.get<any>(api_v1 + `invoices/gus/${taxId}`);
  }

  // Additional Services
  createAdditionalService(data) {
    return this.http.post<any>(api_v1 + 'additional-services', data);
  }

  updateAdditionalService(additionalServiceId, data) {
    return this.http.put<any>(api_v1 + 'additional-services/' + additionalServiceId, data);
  }

  deleteAdditionalService(additionalServiceId) {
    return this.http.delete<any>(api_v1 + 'additional-services/' + additionalServiceId);
  }

  // Stripe
  stripeCheckoutSessionSubscription(data) {

    const successUrl = '/app/premium/payment/success'
    const cancelUrl = '/app/premium/payment/canceled'

    const translatedSuccessUrl = this.localize.translateRoute(successUrl)
    const translatedCancelUrl = this.localize.translateRoute(cancelUrl)

    data.success_url = environment.host + translatedSuccessUrl,
      data.cancel_url = environment.host + translatedCancelUrl

    return this.http.post(api_v1 + 'premium/stripe_checkout_session.php', data)
  }


  stripeManageSubscription() {

    const successUrl = '/app/premium/payment/success'
    const cancelUrl = '/app/premium/payment/canceled'

    const translatedSuccessUrl = this.localize.translateRoute(successUrl)
    const translatedCancelUrl = this.localize.translateRoute(cancelUrl)

    return this.http.post(api_v1 + 'premium/stripe_portal_session.php', {
      success_url: environment.host + translatedSuccessUrl,
      cancel_url: environment.host + translatedCancelUrl
    })
  }

  stripeCancelSubscription() {
    return this.http.post(api_v1 + 'premium/stripe_cancel_subscription.php', {})
  }

  subscriptionCheck() {
    return this.http.post(api_v1 + 'premium/subscription_check.php', {})
  }

  // Premium
  buyPremium(requestData) {
    return this.http.post(api_v1 + 'subscription/buy', requestData);
  }

  getSubscriptionPricing() {
    return this.http.get<any>(api_v1 + 'subscription/pricing');
  }

  addDefaultRate() {
    return this.http.post<any>(api_v1 + 'rates/default', {});
  }

}
