import { Injectable } from "@angular/core";
import { ApplicationData } from "../data/application-data";
import { DbReservation } from "../models/DbReservation";
import { DbRoom } from "../models/DbRoom";
import { Utils } from "../others/utils";
import { RoomsService } from "./rooms.service";

@Injectable({
    providedIn: 'root'
})

export class AvailabilityService {

    
    constructor(
        private _roomsService: RoomsService
    ) {

    }

    getAvailability(checkIn: Date | any, checkOut: Date | any, onlySize: number, onlyType: number, exludedReservationIds: number[] = []): Availability {
        
        if(checkIn?.isLuxonDateTime) checkIn = new Date(
            checkIn.c.year,
            checkIn.c.month - 1,
            checkIn.c.day,
            0,0,0,0
        )
        if(checkOut?.isLuxonDateTime) checkOut = new Date(
            checkOut.c.year,
            checkOut.c.month - 1,
            checkOut.c.day,
            0,0,0,0
        )

        if(!(checkIn instanceof Date)) {
            console.error('[Availability Service]: Checkin is not date.')
        }
        if(!(checkOut instanceof Date)) {
            console.error('[Availability Service]: Checkout is not date.')
        }
        var checkInTimezoneOffset = checkIn.getTimezoneOffset() * 60000;
        let checkInAt = new Date(checkIn.getTime() - checkInTimezoneOffset);

        var checkOutTimezoneOffset = checkOut.getTimezoneOffset() * 60000;
        let checkOutAt = new Date(checkOut.getTime() - checkOutTimezoneOffset);

        const availableRooms: DbRoom[] = [];
        const roomSizes = new Set();
        const roomTypes = new Set();

        const availableRoomSizes = new Set();
        const availableRoomTypes = new Set();

        // Add filters to response
        // Parse for unexpected results
        if (!Utils.isNullOrEmpty(onlySize)) roomSizes.add(onlySize)
        if (!Utils.isNullOrEmpty(onlyType)) roomTypes.add(onlyType)

        // Check each room for availability
        Object.entries(this._roomsService.getRooms())
            .filter(
                (room: [string, DbRoom]) => room[1].status != 0
            )
            .forEach((room: [string, DbRoom]) => {

                if (typeof room[1].reservations == 'undefined') room[1].reservations = [];

                const reservations = room[1].reservations.filter((reservation: DbReservation) => !exludedReservationIds.includes(reservation.reservationId));

                roomTypes.add(room[1].roomType)
                roomSizes.add(room[1].persons)

                let available = this.isReservationAvailable(reservations, checkInAt, checkOutAt)

                if (available) {

                    // availableRoomSizes.add(parseInt(room[1].persons.toString()))
                    if (Utils.isNullOrEmpty(onlyType) || onlyType == room[1].roomType) {
                        availableRoomSizes.add(room[1].persons)
                    }
                    if (Utils.isNullOrEmpty(onlySize) || onlySize == room[1].persons) {
                        availableRoomTypes.add(room[1].roomType)
                    }

                    let matchesSize = true;
                    let matchesType = true;

                    if (!Utils.isNullOrEmpty(onlySize)) if (onlySize != room[1].persons) matchesSize = false
                    if (!Utils.isNullOrEmpty(onlyType)) if (onlyType != room[1].roomType) matchesType = false

                    if (matchesSize && matchesType) {
                        availableRooms.push(room[1]);
                    }

                    // Add only if is not selected
                }
            })

        let roomSizesArray = Array.from(roomSizes)
        roomSizesArray.sort((a: number, b: number) => a - b);

        // Convert type to object from data
        let roomTypesArray = [];
        roomTypes.forEach(type => {
            let roomType = ApplicationData.RoomTypes.find(x => x.id == type);
            if (typeof roomType != 'undefined') {
                roomTypesArray.push(roomType)
            }
        })

        roomSizesArray = Utils.sortByValidNumbers(roomSizesArray, availableRoomSizes);
        roomTypesArray = Utils.sortByValidNumbers(roomTypesArray, availableRoomTypes);
        // Sort rooms
        availableRooms.sort((a: any, b: any) => a.order - b.order)
        return {
            checkIn: checkIn,
            checkOut: checkOut,
            availableRooms: availableRooms,
            roomSizes: roomSizesArray,
            roomTypes: roomTypesArray,
            availableRoomSizes: availableRoomSizes,
            availableRoomTypes: availableRoomTypes
        };
    }

    isReservationAvailable(reservations, s: Date, e: Date) {

        if (typeof reservations == 'undefined') { return true; }
        if (reservations.length === 0) { return true; }

        s = Utils.toDate(s);
        e = Utils.toDate(e);

        let start = s.getTime()
        let end = e.getTime()

        for (let i = 0; i < reservations.length; i++) {
            const reservation = reservations[i];

            let rStart = new Date(reservation.arrival).getTime()
            let rEnd = new Date(reservation.departure).getTime()
            if (
                (start >= rStart && start < rEnd) ||
                (end > rStart && end <= rEnd) ||
                (start < rStart && end > rEnd)
            ) {
                return false;
            }
        }
        // Jeśli nie ma kolizji z żadną z istniejących rezerwacji, zwracamy true
        return true;
    }
}

type Availability = {
    checkIn: Date,
    checkOut: Date
    availableRooms: DbRoom[],
    roomSizes: any,
    roomTypes: any,
    availableRoomSizes: any,
    availableRoomTypes: any
}